import React from 'react'
import profil from '../assets/images/avatar.png';
import check from '../assets/images/check.png';
import uncheck from '../assets/images/uncheck.jpg';
import API from '../lib/api';

function FormJoueurs({openCreate, setOpenCreate, titre, inputs,  list, allLists}) {
  return (
    <div className={openCreate ? 'form-add open': 'form-add'}>
      <h2>{titre}</h2>
        {
            inputs && inputs.map((input, index) => (
                <div key={index} className="form-text">
                    <label>{input.key} : {input.value}</label>
                </div>
            ))
        }
        {
          allLists && allLists.map((list, index) => (
            <div className="form-text">
              <label>
                <span>  {list.title}  </span>
              </label>
              <ul>
                  {
                      list.items && list.items[0] ? list.items.map((row, indRow) => (
                          <li key={indRow}>
                              {
                                  <div>
                                    {/* <div className="icon-bon">
                                      <img src={row.bon && row.bon.imageIcone ?  (API.BASE_URL + API.PATH_FILE + row.bon.imageIcone) : '?'} className="avatar" alt="avatar" />
                                    </div> */}
                                    <div className="nom-profil" ><strong>{row.value}</strong><strong style={{float: 'right'}}>--</strong></div>
                                  </div>
                              }
                              {/* <p>
                                  {row.value}
                                  {
                                      row.check &&<span style={{float: 'right'}}>  <img src={row.check === 'check' ? check : uncheck} className="check" alt="check" />  </span>
                                  }
                              </p> */}
                          </li>
                      ))
                      :
                      <span style={{fontStyle: 'italic', color: 'red'}}>Aucun {list.name} pour le moment</span>
                  }
              </ul>
            </div>
          ))
        }
        <div className="form-submit">
          <input type="reset" name="" id="" value="Fermer" className="reset" onClick={e=> setOpenCreate(false)} />
        </div>
    </div>
  );
}

export default FormJoueurs;
