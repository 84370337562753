import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting';
import GaugeChart from 'react-gauge-chart';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import QUERY from '../../lib/query';
import Aside from '../Aside/Aside';
import { cubicBezier, Gauge } from 'gauge-chart-js';

import { IgrRadialGauge } from 'igniteui-react-gauges';
import { IgrRadialGaugeRange } from 'igniteui-react-gauges';
import { IgrRadialGaugeModule } from 'igniteui-react-gauges';
import { isWithinInterval } from 'date-fns';

IgrRadialGaugeModule.register();

// import $ from "jquery";
// require('')
require("highcharts/modules/timeline")(Highcharts);
require("highcharts/modules/networkgraph")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
// require("highcharts/modules/solid-gauge")(Highcharts);

if (typeof Highcharts === 'object') {
    highcharts3d(Highcharts)
    HighchartsExporting(Highcharts)
    
}

const options = [
    { label: 'Thing 1', value: 1},
    { label: 'Thing 2', value: 2},
];


var opts = {

    size: 300,
    currentValue: 57,
    dialColor: "#000000",
    progressColor: "#FF0000",
    needleColor: '#000000',
    tickColor: '#000000',
    needleBaseColor: "#FF0000",
    progressWidth: 20,
    tickLength: 8,
    tickWidth: 2,
    dialWidth: 17,
    needleWidth: 9,
    needleBaseSize: 10,
    needleSharp: true
};

function TimesheetGlobal() {
    const [reponsePieChart, setReponsePieChart] = useState(null);
    const [reponseChart, setReponseChart] = useState(null);
    const [reponseSpeedometer, setReponseSpeedometer] = useState(null);
    const [reponseChartCoutRevient, setReponseChartCoutRevient] = useState(null);
    const [reponseTimelineChart, setReponseTimelineChart] = useState(null);
    const [reponseLine, setReponseLine] = useState(null);
    const [reponseEstimeTravaile, setReponseEstimeTravaile] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date().setFullYear(2000));
    const [currentDate2, setCurrentDate2] = useState(new Date().setFullYear(2000));
    const [projets, setProjets] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentUsers, setCurrentUsers] = useState([]);
    const [currentProjets, setCurrentProjets] = useState([]);
    const [projetHeuresList, setProjetHeuresList] = useState([]);
    const [packages, setPackages] = useState([]);
    const [allPackages, setAllPackages] = useState([]);
    const [currentPackages, setCurrentPackages] = useState([]);
    const [blocking, setBlocking] = useState(false)
    const [tempsTotal, setTempsTotal] = useState(0)
    const [target, setTarget] = useState(null);
    const [gauge, setGauge] = useState(null);
    const [coutTotal, setCoutTotal] = useState(0);
    const [FI, setFI] = useState(0);
    const [plages, setPlages] = useState([]);

    useEffect(() => {
        var date1 = new Date();
        date1.setHours(0);
        date1.setMinutes(0);
        date1.setSeconds(0);
        var date2 = new Date();
        date2.setHours(23);
        date2.setMinutes(59);
        date2.setSeconds(59);
        setCurrentDate(date1);
        setCurrentDate2(date2);
        QUERY.getFraisIndirect()
        .then((data) => {
            console.log(data);
            const fraisIndirect = data.find(e => e.code === 'FI') && data.find(e => e.code === 'FI').valeur ? parseFloat(data.find(e => e.code === 'FI').valeur) : 0;
            var FI_PLAGEs = [];
            data.forEach(frais => {
                if (frais.code === 'PLAGE') {
                    let _dates = frais.nom.split(" ")[2].split('-');
                    let date1 = `${_dates[0].split('/')[1]}-${_dates[0].split('/')[0].substring(1)}-2000`;
                    let date2 = `${_dates[1].split('/')[1].substring(0, _dates[1].split('/')[1].length -1)}-${_dates[1].split('/')[0]}-2000`;
                    FI_PLAGEs.push({
                        debut: new Date(date1),
                        fin: new Date(date2),
                        val: frais && frais.valeur ? parseFloat(frais.valeur) : fraisIndirect
                    });
                }
            });
            setPlages(FI_PLAGEs);
            setFI(fraisIndirect);
            QUERY.allProjets()
            .then(data => {
                const projets = data.map(d => ({
                    label: d.name,
                    price: d.pr_budget_type_amount,
                    value: d.id
                }));
                setProjets([
                ...projets]);
                if (projets && projets[0]) {
                    setCurrentProjets([
                        projets[0]
                    ]);
                }

                QUERY.allPackages()
                .then(data => {
                    const packages = data.map(d => ({
                        label: d.name,
                        value: d.id,
                        projetId: d.projet.id
                    }));
                    const _packages = [
                        ...packages.filter(pack => {
                            return ((projets && projets[0]) ? [projets[0]] : []).find(projet => projet.value == pack.projetId)
                        })
                    ];
                    setPackages([{
                        label: "TOUT", 
                        value: "*",
                        projetId: 0
                    },
                    ..._packages]);
                    setAllPackages([{
                        label: "TOUT", 
                        value: "*",
                        projetId: 0
                    },
                    ...packages]);
                    setCurrentPackages([{
                        label: "TOUT", 
                        value: "*",
                        projetId: 0
                    },
                    ..._packages])

                    initDate((projets && projets[0]) ? [projets[0]] : [], packages, fraisIndirect, FI_PLAGEs);
                })
                .catch(err => {
                })
            })
            .catch(err => {
            })
        }).catch(err => {
        })
    }, [])

    

    const handlePackageChanges = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            setCurrentPackages(packages);
            initDate(currentProjets, packages)
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setCurrentPackages([]);
            initDate(currentProjets, [])
        } else if (event.action === "deselect-option") {
            setCurrentPackages(value.filter(o => o.value !== "*"));
            initDate(currentProjets, value.filter(o => o.value !== "*"))
        } else if (value.length === packages.length - 1) {
            setCurrentPackages(packages);
            initDate(currentProjets, packages)
        } else {
            setCurrentPackages(value);
            initDate(currentProjets, value)
        }
    }

    const handleProjetChanges = (value, event) => {
        let _projets = [];
        if (event.action === "select-option" && event.option.value === "*") {
            _projets = projets;
        } 
        else if (event.action === "deselect-option" && event.option.value === "*") {
            _projets = [];
        } 
        else if (event.action === "deselect-option") {
            _projets = [];
        } 
        // else if (value.length === projets.length - 1) {
        //     _projets = projets;
        // } 
        else {
            _projets = [value[value.length - 1]];
        }
        const _packages = [{
                label: "TOUT", 
                value: "*",
                projetId: 0
            },
            ...allPackages.filter(pack => {
                return _projets.find(projet => projet.value == pack.projetId)
            })
        ]
        setPackages(_packages);
        setCurrentPackages(_packages);
        setCurrentProjets(_projets);
        initDate(_projets, _packages)
    }

    
    const initDate = (projetIds, packageIds, _FI = FI, _plages = plages) => {
        var _projetIds = '[';
        var _packageIds = '[';
        projetIds.forEach(projet => {
            if (projet.value != "*") {
                _projetIds += `"${projet.value}" `;
            }
        });
        packageIds.forEach(pack => {
            if (pack.value != "*") {
                _packageIds += `"${pack.value}" `;
            }
        });
        _projetIds += ']';
        _packageIds += ']';
        // setBlocking(true);
        QUERY.getUserTimesheetProjetFilter({projetIds: _projetIds, packageIds: _packageIds})
        .then((res) => {
            const users = res.allUserBexios;
            const projets = res.allProjets;
            console.log(res);
            let data = []; 
            let dataFull = [];
            let dataCoutRevient = []; 
            let dataProjet = [];
            let dataProjetEstime = [];
            let dataTimeline = [];
            let _tempsTotal = 0;
            let _coutTotal = 0;
            let categories = users.map((user, index) => {
                if (!user.isHidden) {
                    var duree = 0;
                    var coutUser = 0;
                    user && user.timesheets && user.timesheets.forEach(timesheet => {
                        const heureMinute = timesheet.duration && timesheet.duration != 'null' ? timesheet.duration.split(':') : ['0','0'];
                        const _duree = parseFloat((parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60))).toFixed(2));
                        duree += _duree;
                        var date2000 = (new Date(timesheet.date));
                        date2000.setFullYear(2000);
                        const _plage = _plages.find(e => {
                            return isWithinInterval(
                                date2000,
                                { start: e.debut, end: e.fin }
                            )
                        });
                        coutUser += _plage && _plage.val && user && user.cout ? (user.cout + (user.exclureFraisIndirect ? 0 : _plage.val)) * _duree : (user.cout + (user.exclureFraisIndirect ? 0 : _FI)) * _duree;
                    });
                    dataFull.push({
                        name: user ? (user.firstname + ' ' + user.lastname) : '',
                        cout: coutUser,
                        duree
                    })
                    _tempsTotal += duree ? duree : 0;
                    return user ? (user.firstname + ' ' + user.lastname) : ''
                }
            });
            dataFull = dataFull.sort((x,y) => y.duree-x.duree);
            categories = [];
            dataFull.forEach(d => {
                categories.push(d.name)
                data.push(parseFloat(d.duree.toFixed(2)));
                dataCoutRevient.push(parseFloat((d.cout).toFixed(2)));
                _coutTotal += d.cout;
            });
            console.log('dataFull : ', dataFull)
            setTempsTotal(_tempsTotal);
            setCoutTotal(_coutTotal);
            let categorieProjets = projets.map((projet, index) => {
                var duree = 0;
                var userTimesheets = [];
                projet && projet.timesheets && projet.timesheets.forEach(timesheet => {
                    const heureMinute = timesheet.duration ? timesheet.duration.split(':') : ['0','0'];
                    const currentDuree = (parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60).toFixed(2)));
                    duree += currentDuree;
                    const usr = userTimesheets.find(user => timesheet && timesheet.utilisateur && user.id === timesheet.utilisateur.id);
                    if (usr) {
                        usr.duree += currentDuree;
                    }
                    else {
                        userTimesheets.push({
                            name: timesheet && timesheet.utilisateur ? (timesheet.utilisateur.firstname + ' ' + timesheet.utilisateur.lastname) :  '----- -----',
                            duree: currentDuree,
                            id: timesheet && timesheet.utilisateur ? timesheet.utilisateur.id : ''
                        })
                    }
                });
                dataProjet.push([(projet ? projet.name : ''), duree]);
                dataProjetEstime.push([(projet ? projet.name : ''), duree + 100]);
                var description = '';
                userTimesheets && userTimesheets.forEach((timeline, index) => {
                    description += ((index != 0 ? ' - ' : '') + (timeline.name + ' : ' + timeline.duree + ' heure(s)')) 
                });
                dataTimeline.push({
                    name: (projet ? projet.name : ''),
                    label: (currentDate ? (`${('0' + ((new Date(currentDate)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate)).getFullYear()}`) : '..-..-..') + ' ; ' + duree + ' heure(s)',
                    description
                })
                return projet.name
            });

            setProjetHeuresList(dataProjet.sort((x,y) => y[1]-x[1]));


            setReponseChart({
                    chart: {
                        type: 'column',
                    },
                    xAxis: {
                        categories
                    },
                    title: {
                        text: null
                    },
                    plotOptions: {
                        series: {
                            depth: 25,
                            colorByPoint: true
                        }
                    },
                    series: [{
                        data: data,
                        showInLegend: false
                    }
                ]
            });


            setReponseChartCoutRevient({
                    chart: {
                        type: 'column',
                    },
                    xAxis: {
                        categories
                    },
                    title: {
                        text: null
                    },
                    plotOptions: {
                        series: {
                            depth: 25,
                            colorByPoint: true
                        }
                    },
                    series: [{
                        data: dataCoutRevient,
                        showInLegend: false
                    }
                ]
            });

            setReponseEstimeTravaile({
                chart: {
                    type: 'bar',
                    height: '80%'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: categorieProjets,
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Population (millions)',
                        align: 'high'
                    },
                    labels: {
                        overflow: 'justify'
                    }
                },
                tooltip: {
                    valueSuffix: ' millions'
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                    x: -40,
                    y: 80,
                    floating: true,
                    borderWidth: 1,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                    shadow: true
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Temps de travail estimé',
                    color: "#000",
                    data: dataProjetEstime
                }, {
                    name: 'Temps de travail effectué',
                    color: "#FF0000",
                    data: dataProjet
                }]
            })

            setReponsePieChart({
                chart: {
                    type: 'pie',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    options3d: {
                        enabled: true,
                        alpha: 45,
                        beta: 0
                    }
                },
                title: {
                    text: null
                },
                accessibility: {
                    point: {
                        valueSuffix: 'heure(s)'
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}heure(s) -> {point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        depth: 35,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Timesheet',
                    data: dataProjet
                }]
            });

            setBlocking(false);
        })
        .catch((err) => {
          console.log(err);
          setBlocking(false);
        });
    }

  return (
    <>
        <Aside/>
        <div className="content-page">
            <h1>Côuts et heures des projets</h1>

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={projets} 
                onChange={handleProjetChanges}
                value={currentProjets}
            />

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={packages} 
                onChange={handlePackageChanges}
                value={currentPackages}
            />

            {/* <DateRangePicker className="time-picker"
                onChange={e => handleDateChange(e)}
                value={[currentDate, currentDate2]}
            /> */}

            <BlockUi tag="div" blocking={blocking}>

                <div className="full-width">

                    <div className="list-winner col-md-6">
                        <div className="content">
                            <h2 style={{textAlign: 'center'}}>Heures travaillées</h2>
                            <div className="img-diagramme">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={reponseChart}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="list-winner col-md-6">
                        <div className="content">
                            <h2 style={{textAlign: 'center'}}>Coût de revient</h2>
                            <div className="img-diagramme">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={reponseChartCoutRevient}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-width">

                    <div className="list-winner col-md-3">
                        <div className="">
                            <h1 style={{textAlign: 'center', marginTop: '90px'}}>Finance du projet</h1>
                            
                            <h2 style={{textAlign: 'center'}}>Frais direct et indirect des employés</h2>
                            <div className="contentPrice" style={{background: '#000', color: "#ffffff", position: 'absolute', bottom: '0px'}}>
                                <h2 style={{textAlign: 'center'}}>Moyenne coût horaire</h2>
                                <h1 style={{textAlign: 'center'}}>{coutTotal && tempsTotal ? parseFloat(coutTotal / tempsTotal).toFixed(2) : '00'} CHF</h1>
                            </div>
                        </div>
                    </div>

                    <div className="list-winner col-md-6">
                        <div className="content">
                            <h2 style={{textAlign: 'center'}}>Projets travaillés (Heures et Pourcentages)</h2>
                            <div className="img-diagramme conical-gauge-wrapper">

                                {/* <Gauge {...opts} /> */}
                                {/* <div className="conical-gauge" id="gauge"></div>
                                <span class="label">56</span> */}
                                <IgrRadialGauge
                                    height="70%"
                                    width="100%"
                                    minimumValue={-100} 
                                    value={((currentProjets && currentProjets[0] ? parseFloat((currentProjets[0].price - coutTotal).toFixed(2)) : 0) / (currentProjets && currentProjets[0] ? parseFloat(currentProjets[0].price.toFixed(2)) : 0) * 100)}
                                    maximumValue={100}
                                    backingOutline="#000"
                                    rangeBrushes ="#FF0000, #f1c40f, #009432"
                                    rangeOutlines="#FF0000, #f1c40f, #009432"  >
                                    <IgrRadialGaugeRange name="range1"
                                            startValue={-102} endValue={0}
                                            innerStartExtent={0.50} innerEndExtent={0.50}
                                            outerStartExtent={0.57} outerEndExtent={0.57} />
                                    <IgrRadialGaugeRange name="range2"
                                        startValue={0} endValue={20}
                                        innerStartExtent={0.50} innerEndExtent={0.50}
                                        outerStartExtent={0.57} outerEndExtent={0.57} />
                                    <IgrRadialGaugeRange name="range3"
                                        startValue={20} endValue={102}
                                        innerStartExtent={0.50} innerEndExtent={0.50}
                                        outerStartExtent={0.57} outerEndExtent={0.57} />
                                </IgrRadialGauge>
                                
                            <div className="list-winner1">
                                <div className="contentPriceMiddle col-md-5" style={{background: '#ffffff', color: "#000", float: "left"}}>
                                    <h2 style={{textAlign: 'center'}}>Temps Total</h2>
                                    <h1 style={{textAlign: 'center'}}>{parseFloat(tempsTotal.toFixed(2))}</h1>
                                </div>
                                <div className="contentPriceMiddle col-md-5" style={{background: '#ffffff', color: "#000", float: "right", boxShadow: 'rgb(25 42 70 / 11%) -10px 8px 15px 0px'}}>
                                    <h2 style={{textAlign: 'center'}}>% consommé</h2>
                                    <h1 style={{textAlign: 'center'}}>{parseFloat(((coutTotal / (currentProjets && currentProjets[0] ? currentProjets[0].price : 1)) * 100).toFixed(2))}</h1>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="list-winner col-md-3">
                        <div className="contentPrice" style={{background: '#e67e22', color: "#ffffff"}}>
                            <h2 style={{textAlign: 'center'}}>Prix de vente</h2>
                            <h1 style={{textAlign: 'center'}}>{currentProjets && currentProjets[0] ? parseFloat(currentProjets[0].price.toFixed(2)) : '0'} CHF</h1>
                        </div>
                        <div className="contentPrice" style={{background: '#ff0000', color: "#ffffff"}}>
                            <h2 style={{textAlign: 'center'}}>Coût de revient</h2>
                            <h1 style={{textAlign: 'center'}}>{parseFloat(coutTotal.toFixed(2))} CHF</h1>
                        </div>
                        <div className="contentPrice" style={{background: '#000', color: "#ffffff"}}>
                            <h2 style={{textAlign: 'center'}}>Marge</h2>
                            <h1 style={{textAlign: 'center'}}>{(currentProjets && currentProjets[0] ? parseFloat((currentProjets[0].price - coutTotal).toFixed(2)) : 0)} CHF</h1>
                        </div>
                    </div>
                </div>

            </BlockUi>

        </div>
    </>
  );
}

export default TimesheetGlobal;

// 774170537