import React, { useEffect, useState } from 'react'
import QUERY from '../../lib/query';
import Aside from '../Aside/Aside';

function TimesheetPersonne() {

    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState('');
    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        QUERY.getLightUserBexios()
        .then(data => {
            setUsers(data);
            if (data && data[0]) {
                setSelected(data[0].id);
                setSelectedUser(data[0]);
            }
        })
        .catch(err => {
            
        })
    }, [])

  return (
    <>
        <Aside/>
        <div className="content-page">
            <h1>Timesheet par personne</h1>


            <div className="content-listes">
                <div className="list" id="list">
                    <ul> 
                        {
                            users && users.map((user) => (
                                <li key={user.id} onClick={e => {
                                    setSelected(user.id);
                                    setSelectedUser(user)
                                }}>
                                    <a  className={selected == user.id ? "selected" : ""}> {user.firstname} {user.lastname} </a>
                                </li>
                            ))
                        }
                    </ul> 
                    <div className="stat">
                        <div>
                            <h4>{selectedUser.firstname} {selectedUser.lastname}</h4>
                        </div>
                    </div>
                </div> 
            </div>
        
        </div>
    </>
  );
}

export default TimesheetPersonne;
