import CLIENT from './config';
import { gql } from '@apollo/client';

const client = CLIENT.client;
const clientStream = CLIENT.clientStream;
 
// Login in
const LOGIN = async data => {
    const mutation = gql`
      mutation{
        authenticateUserWithPassword(
          email: "${data.email}"
          password: "${data.password}"
        )
        {
          token
          item {
            id
            prenom
            nom
            email
            isAdmin
          }
        }
      }
    `; 
  
    let result = await client.mutate({ mutation });
    return result.data.authenticateUserWithPassword;
}
  
// Delete anything
const DELETE = async data => {
  const mutation = gql`
    mutation {
      delete${data.list}(
        id: "${data.id}"
      ) {
        id
      }
    }
  `; 

  let result = await client.mutate({ mutation });
  return result.data;
}

const UpdateOrder = async data => {
  const mutation = gql`
    mutation {
      updateQuestion(
        id: "${data.id}"
        data: {
          ordre: ${data.ordre}
        }
      ) {
        id
      }
    }
  `; 

  let result = await client.mutate({ mutation });
  return result.data;
}

const UpdateParametrage = async data => {
  const mutation = gql`
    mutation {
      updateParametrage(
        id: "${data.id}"
        data: {
          nom: "${data.nom}"
          valeur: "${data.valeur}",
          description: "${data.description}"
        }
      ) {
        id
      }
    }
  `; 

  let result = await client.mutate({ mutation });
  return result.data;
}

const CreateParametrage = async data => {
  const mutation = gql`
    mutation {
      createParametrage(
        data: {
          code: "${data.code}"
          unite: "${data.unite}"
          valueType: ${data.valueType}
          nom: "${data.nom}"
          valeur: "${data.valeur}",
          description: "${data.description}"
        }
      ) {
        id
      }
    }
  `; 

  let result = await client.mutate({ mutation });
  return result.data;
}

const DeleteParametrage = async data => {
  const mutation = gql`
    mutation {
      deleteParametrage(
        id: "${data.id}"
      ) {
        id
      }
    }
  `; 

  let result = await client.mutate({ mutation });
  return result.data;
}
  
// Create anything
const CREATE = async data => {
  if (data.idUpdate && data.list === 'Question') {
    const _mutation = gql`
      mutation {
        updateQuestion(
          id: "${data.idUpdate}"
          data: {
            reponseProposees: {
              disconnectAll: true
            }
            bonneReponses: {
              disconnectAll: true
            }
          }
        ) {
          id
        }
      }
  `; 
    let res = await client.mutate({ mutation: _mutation });
    console.log(res)
  }

  var mutation;
  if (data.idUpdate) {
    console.log(`
    mutation {
      update${data.list}(
        id: "${data.idUpdate}"
        data: ${data.value}
      ) {
        id
      }
    }
`)
    mutation = gql`
      mutation {
        update${data.list}(
          id: "${data.idUpdate}"
          data: ${data.value}
        ) {
          id
        }
      }
  `; 
  }
  else {
    mutation = gql`
      mutation {
        create${data.list}(
          data: ${data.value}
        ) {
          id
        }
      }
  `; 
  }

  let result = await client.mutate({ mutation });
  return result.data[data.idUpdate ? `update${data.list}` : `create${data.list}`];
}

const ADD_PJ = async data => {
  console.log("data : ", data);
  const mutation = gql`
    mutation($PJ: Upload!) {
      localUpload(
        PJ: $PJ
      ) {
        filename
      }
    }
  `;  

  let result = await client.mutate({ mutation, variables: { PJ: data.PJ } });
  return result.data;
}

const ADD_ICON_IMAGE = async data => {
  const mutation = gql`
    mutation($id: String!, $list: String!, $file: Upload!) {
      addIconImage(
        id: $id
        list: $list
        file: $file
      )
    }
  `;  

  let result = await client.mutate({ mutation, variables: { id: data.id, file: data.file, list: data.list } });
  return result.data;
}

const OperationFincanciere = async (data) => {
  console.log(`
  mutation {
    createOperationFinanciere(
      data: {
        fichier: ${data}
        date: ${(new Date()).toISOString()}
      }
    ) {
      id
      rapportChargement
    }
  }
`)
  const mutation = gql`
    mutation($file: Upload!) {
      createOperationFinanciere(
        data: {
          fichier: $file
        }
      ) {
        id
      }
    }
  `; 

  let result = await client.mutate({ mutation, variables: { file: data }  });
  return result.data.createOperationFinanciere;
}
  
export default {
    LOGIN,
    DELETE,
    CREATE,
    UpdateOrder,
    ADD_PJ,
    ADD_ICON_IMAGE,
    UpdateParametrage,
    CreateParametrage,
    DeleteParametrage,
    OperationFincanciere
}