import swal from 'sweetalert';

const alert = (type, titre, description, buttons = [], dangerMode = false) => {
    return swal({
        title: titre,
        text: description,
        icon: type,
        buttons: buttons && buttons.length > 0 ? buttons : null,
        dangerMode: dangerMode,
      })
}

export default {
    alert
}