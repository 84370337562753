import React , { Fragment, useState } from 'react'
import Aside from '../Aside/Aside'
import Listes from './Listes'
import Form from './Form';
import Edit from './Edit';

function Joueurs() {
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [update, setUpdate] = useState({});
  const [newInput, setNewInput] = useState(true);
  return (
    <Fragment>
      <Aside/>
      <Listes refresh={refresh} setUpdate={setUpdate} setNewInput={setNewInput} setRefresh={setRefresh} setOpenCreate={setOpenCreate} setOpenEdit={setOpenEdit}/>
      {
        openCreate && <Form setRefresh={setRefresh} newInput={newInput} update={update} setUpdate={setUpdate} setOpenCreate={setOpenCreate} openCreate={openCreate}/>
      }
      {
        openEdit && <Edit setRefresh={setRefresh} newInput={newInput} update={update} setUpdate={setUpdate} setOpenCreate={setOpenEdit} openCreate={openEdit}/>
      }
    </Fragment>
  );
}

export default Joueurs;
