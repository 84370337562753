import React, { useEffect, useState } from 'react'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import icon from '../assets/images/jcr_content.jpg';
import check from '../assets/images/check.png';
import uncheck from '../assets/images/uncheck.jpg';
import move from '../assets/images/move.svg';
import download from '../assets/images/download.svg';
import synch from '../assets/images/refresh.svg';
import leftArrow from '../assets/images/left-arrow.svg';
import rightArrow from '../assets/images/right-arrow.svg';
import { CSVLink, CSVDownload } from "react-csv";
import alert from '../lib/alert';
import QUERY from '../lib/query';

function Listes({typeSynchro, puces, title, headers, list, setOpenCreate, setOpenCreateOrdonner, setAction, setNewInput, noAdd, dragElement, csv, sync, csvData, pagination, page, size, totalPage, setPage, search, setSearch, setSize}) {
    const [_list, set_List] = useState(list);

    const [selectedPuce, setSelectedPuce] = useState({
        id: 1,
        firstname: 'Mbaye',
        lastname: 'DIOP'
    });

    const [selected, setSelected] = useState(1);

    const setFilter = (filter = '') => {
        setPage(1);
        setSearch(filter);
        // set_List(list.filter(row => (row.find(e => e.type === 'TEXT' && e.value && e.value.toLowerCase() && e.value.toLowerCase().includes(filter.toLowerCase())))));
    }

    const downloadCSV = () => {
        document.getElementById('downloadElement').click();
    }

    const synchro = () => {
        switch(typeSynchro) {
            case 'TIMESHEET' : 
                QUERY.getTimesheets({limit: 300000})
                .then(data => {

                })
                .catch(err => {
                    
                })
            break;
            case 'USER' : 
                QUERY.getUsers({limit: 300000})
                .then(data => {

                })
                .catch(err => {
                    
                })
            break;
            case 'TYPEPROJET' : 
                QUERY.getProjetTypes({limit: 300000})
                .then(data => {

                })
                .catch(err => {
                    
                })
            break;
            case 'PROJET' : 
                QUERY.getProjets({limit: 300000})
                .then(data => {

                })
                .catch(err => {
                    
                })
            break;
        }
    }

    useEffect(() => {
        set_List(list);
    }, [list]);

    return (
        <div className="content-page">
        <h1>{title}</h1>
        {
            pagination && <div className="pagination">
                <ul>
                    <li><a href="#" onClick={e => {
                            setPage(page > 1 ? (page -1) : page = 1);
                        }}><img style={{width: '13px'}} src={leftArrow} /></a></li>
                    {
                        page > 3 &&
                        <li><a href="#" onClick={e => {
                            setPage(1)
                        }}>...</a></li>
                    }
                    {
                        page - 2 > 0 &&
                        <li><a href="#" onClick={e => {
                            setPage(page - 2)
                        }}>{page - 2}</a></li>
                    }
                    {
                        page - 1 > 0 &&
                        <li><a href="#" onClick={e => {
                            setPage(page - 1)
                        }}>{page - 1}</a></li>
                    }
                    <li><a href="#" className="actif">{page}</a></li>
                    {
                        page + 1 <= totalPage &&
                        <li><a href="#" onClick={e => {
                            setPage(page + 1)
                        }}>{page + 1}</a></li>
                    }
                    {
                        page + 2 <= totalPage &&
                        <li><a href="#" onClick={e => {
                            setPage(page + 2)
                        }}>{page + 2}</a></li>
                    }
                    {
                        totalPage - page >= 3 &&
                        <li><a href="#" onClick={e => {
                            setPage(totalPage)
                        }}>...</a></li>
                    }
                    <li><a href="#" onClick={e => {
                            setPage(page < totalPage ? (page +1) : page = totalPage);
                        }}><img style={{width: '13px'}} src={rightArrow} /></a></li>
                </ul>
            </div>
        }{
            pagination && <div style={{marginLeft: 50}} className="pagination">
                <ul>
                    <li>
                        <form>
                            <div className="form-select">
                                <label style={{fontSize: '25px', marginRight: '20px'}}>Size </label>
                                <select style={{height: '56px', border: '1px solid #ff0000'}} onChange={e => {
                                        setPage(1)
                                        setSize(e.target.value);
                                    }} name="size" id="size">
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={75}>75</option>
                                    <option value={100} selected>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={totalPage * size}>TOUT</option>
                                </select>
                            </div>
                        </form>
                    </li>
                </ul>
            </div>
        }
        <div className="btn-add-form">
            <input onChange={e => setFilter(e.target.value)} type="text" name="" id="" placeholder="Recherche" />
            {
                !noAdd && <a href="#" onClick={e=> {
                    setOpenCreate(true);
                    setNewInput(true);
                }} className="btn-add">+</a>
            }
            {
                dragElement && <a href="#" onClick={e=> {
                    setOpenCreateOrdonner(true);
                }} className="btn-add">
                    <img style={{width: '24px'}} src={move} />
                </a>
            }
            {
                csv && <a href="#" onClick={e=> {
                    downloadCSV();
                }} className="btn-add">
                   <img style={{width: '24px'}} src={download} />
                </a>
            }
            {
                sync && <a href="#" onClick={e=> {
                    synchro();
                }} className="btn-add">
                   <img style={{width: '24px'}} src={synch} />
                </a>
            }
        </div>

        {
            csv && csvData && <CSVLink id="downloadElement" data={csvData}></CSVLink>
        }

        {
            !puces &&
            <div className="content-listes">

                <table className="tableau-list">
                <thead>
                    <tr>
                        {
                            headers && headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        _list && _list.map((row, indRow) => (
                            <tr key={ row && row.find(e => e.type === 'ID') ? row.find(e => e.type === 'ID').value : indRow } style={{background: (row && row.find(e => e.type === 'COLOR') ? row.find(e => e.type === 'COLOR').value : 'white'), color: (row && row.find(e => e.type === 'COLOR') && row.find(e => e.type === 'COLOR').value ? 'white' : 'black')}}>
                                {
                                    row && row.map((colonne, indColomn) => {
                                        switch (colonne.type) {
                                            case 'TEXT':
                                                return <td key={ indColomn }>{colonne.value}</td>
                                            case 'TINY':
                                                return <td key={ indColomn } >
                                                            <div dangerouslySetInnerHTML={{ __html: colonne.value }}></div>
                                                        </td>
                                            case 'IMG':
                                                return <td key={ indColomn } style={{width: '70px'}}>
                                                        <div className="img-tab">
                                                        <img src={colonne.value} className="avatar" alt="icon" />
                                                    </div>
                                                </td>
                                            case 'PROFIL':
                                                return <td key={ indColomn }>
                                                        <div className="profil-tab">
                                                        <img src={colonne.value} className="avatar" alt="icon" />
                                                    </div>
                                                </td>
                                            case 'CHECK':
                                                return <td key={ indColomn }>
                                                        <img src={colonne.value ? check : uncheck} className="check" alt="check" />
                                                        <label htmlFor="actif-list"></label>
                                                    </td>
                                            case 'ACTION':
                                                return <td key={ indColomn }>
                                                    <div className="btn-actions">
                                                        {
                                                            colonne.value && colonne.value.map((action, indValue) => (
                                                                <a onClick={e => {setAction(action); setNewInput(false);}} key={indValue} href="#" className={action.type}></a>
                                                            ))
                                                        }
                                                    </div>
                                                </td>
                                            default:
                                                break;
                                        }
                                    })
                                }
                            </tr>
                        ))
                    }
                </tbody>
                </table>
                {/* <Pagination totalRecords={totalCountries} pageLimit={18} pageNeighbours={1} onPageChanged={this.onPageChanged} /> */}
            </div>
        }


        {
            puces && 
            <div className="content-listes">
                <div className="list" id="list">
                    <ul> 
                        {
                            _list && _list.map((puce) => (
                                <li key={puce.id} onClick={e => {
                                    setSelected(puce.id);
                                    setSelectedPuce(puce)
                                }}>
                                    <a  className={selected == puce.id ? "selected" : ""}> {puce.firstname} {puce.lastname} </a>
                                </li>
                            ))
                        }
                    </ul> 
                    <div className="stat">
                        <div>
                            <h4>{selectedPuce.firstname} {selectedPuce.lastname}</h4>
                        </div>
                    </div>
                </div> 
            </div>
        
        }
        

        </div>
    );
}

export default Listes;
