import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { addDays, differenceInDays, startOfWeek, endOfWeek } from 'date-fns';
import QUERY from '../../lib/query';
import Aside from '../Aside/Aside';
import STORAGE from '../../lib/storage';

require("highcharts/modules/timeline")(Highcharts);

if (typeof Highcharts === 'object') {
    highcharts3d(Highcharts)
    HighchartsExporting(Highcharts)
    
}

function TimesheetGlobal() {
    const [reponsePieChart, setReponsePieChart] = useState(null);
    const [reponseChart, setReponseChart] = useState(null);
    const [reponseTimelineChart, setReponseTimelineChart] = useState(null);
    const [reponseLine, setReponseLine] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDate2, setCurrentDate2] = useState(new Date());

    useEffect(() => {
        var date = STORAGE.getDateRange();
        if (!date) {
            date = STORAGE.getDefaultRange();
        }
        const date1 = date.start;
        const date2 = date.end;
        setCurrentDate(date1);
        setCurrentDate2(date2);
        console.log(date1, date2)
        setTimeout(() => {
            initDate(date1, date2);
        }, 200);
    }, [])

    const handleDateChange = (date) => {
        console.log(date)
        setCurrentDate(date[0]);
        setCurrentDate2(date[1]);
        initDate(date[0], date[1])
        STORAGE.setDateRange(date[0], date[1])
    }

    const initDate = (date, date2) => {
        QUERY.getUserTimesheet({date, date2})
        .then((res) => {
            const users = res.allUserBexios;
            const projets = res.allProjets;
            console.log(res);
            let data = []; 
            let dataProjet = [];
            let dataTimeline = [];
            let dataLine = [];
            let categories = users.map((user, index) => {
                var duree = 0;
                user && user.timesheets && user.timesheets.forEach(timesheet => {
                    const heureMinute = timesheet.duration  && timesheet.duration != 'null'? timesheet.duration.split(':') : ['0','0'];
                    duree += (parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60).toFixed(2)));
                });
                data.push(duree);
                return user ? (user.firstname + ' ' + user.lastname + '( <strong>' + duree.toFixed(1) + 'H </strong>)') : ''
            })
            const differenceDays = differenceInDays(date2, date) + 1;
            var labelDays = [];
            var labelDaysCotegorie = [];
            var initialDayValues = [];
            for(let i = 0; i < differenceDays; i++) {
                const day = addDays(date, i);
                labelDaysCotegorie.push(
                    day ? (`${('0' + (day.getDate())).slice(-2)}-${('0' + (day.getMonth() + 1)).slice(-2)}-${day.getFullYear()}`) : '..-..-..'
                );
                labelDays.push({
                    index: i,
                    date: day
                })
                initialDayValues.push(0)
            }
            console.log(labelDaysCotegorie, labelDays)
            let categorieProjets = projets.map((projet, index) => {
                var duree = 0;
                let userTimesheets = [];
                let projetEvolution = [...initialDayValues];
                projet && projet.timesheets && projet.timesheets.forEach(timesheet => {
                    const heureMinute = timesheet.duration ? timesheet.duration.split(':') : ['0','0'];
                    const currentDuree = (parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60).toFixed(2)));
                    duree += currentDuree;
                    const usr = userTimesheets.find(user => timesheet && timesheet.utilisateur && user.id == timesheet.utilisateur.id);
                    if (usr) {
                        usr.duree += currentDuree;
                    }
                    else {
                        userTimesheets.push({
                            name: timesheet && timesheet.utilisateur ? (timesheet.utilisateur.firstname + ' ' + timesheet.utilisateur.lastname) :  '----- -----',
                            duree: currentDuree,
                            id: timesheet && timesheet.utilisateur ? timesheet.utilisateur.id : ''
                        })
                    }
                    const index = differenceDays - (differenceInDays(new Date(timesheet.date), date)) -1;
                    projetEvolution[index] = projetEvolution[index] + currentDuree;
                });
                dataProjet.push([(projet ? projet.name : ''), duree]);
                var description = '';
                userTimesheets && userTimesheets.forEach((timeline, index) => {
                    description += ((index != 0 ? ' - ' : '') + (timeline.name + ' : ' + timeline.duree + ' heure(s)')) 
                });
                dataTimeline.push({
                    name: (projet ? projet.name : ''),
                    label: (currentDate ? (`${('0' + ((new Date(currentDate)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate)).getFullYear()}`) : '..-..-..') + ' ; ' + duree + ' heure(s)',
                    description
                })

                dataLine.push(
                    {
                        name: (projet ? projet.name : ''),
                        data: projetEvolution
                    }
                );
                return projet.name
            })


            setReponseChart({
                    chart: {
                        type: 'column',
                    },
                    xAxis: {
                        categories
                    },
                    title: {
                        text: null
                    },
                    plotOptions: {
                        series: {
                            depth: 25,
                            colorByPoint: true
                        }
                    },
                    series: [{
                        data: data,
                        showInLegend: false
                    }]
            });

            setReponsePieChart({
                chart: {
                    type: 'pie',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    options3d: {
                        enabled: true,
                        alpha: 45,
                        beta: 0
                    }
                },
                title: {
                    text: null
                },
                accessibility: {
                    point: {
                        valueSuffix: 'heure(s)'
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}heure(s) -> {point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        depth: 35,
                        dataLabels: {
                            enabled: false,
                            format: null,
                            distance: -50,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 4
                            }
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Timesheet',
                    data: dataProjet
                }]
            });

            setReponseTimelineChart({
                chart: {
                    type: 'timeline'
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: '<h5>{chartTitle}</h5>' +
                            '<div>{typeDescription}</div>' +
                            '<div>{chartSubtitle}</div>' +
                            '<div>{chartLongdesc}</div>' +
                            '<div>{viewTableButton}</div>'
                    },
                    point: {
                        valueDescriptionFormat: '{index}. {point.label}. {point.description}.'
                    }
                },
                xAxis: {
                    visible: false
                },
                yAxis: {
                    visible: false
                },
                title: {
                    text: null
                },
                series: [{
                    data: dataTimeline
                }]
            });

            setReponseLine({
                chart: {
                    type: 'line'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: labelDaysCotegorie
                },
                yAxis: {
                    title: {
                        text: 'Heures'
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },
                series: dataLine
            })


        })
        .catch((err) => {
          console.log(err);
        });
    }

  return (
    <>
        <Aside/>
        <div className="content-page">
            <h1>Timesheet global</h1>

            <DateRangePicker className="time-picker"
                onChange={e => handleDateChange(e)}
                value={[currentDate, currentDate2]}
            />

            <div className="full-width">
                <div className="list-winner">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Timesheet par projet </h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponsePieChart}
                            />
                        </div>
                    </div>
                </div>

                <div className="list-winner col-md-8">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Timesheet de la date du {currentDate ? (`${('0' + ((new Date(currentDate)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate)).getFullYear()}`) : '..-..-..'} au {currentDate2 ? (`${('0' + ((new Date(currentDate2)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate2)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate2)).getFullYear()}`) : '..-..-..'}</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponseChart}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Timelime des projets de la date du {currentDate ? (`${('0' + ((new Date(currentDate)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate)).getFullYear()}`) : '..-..-..'} au {currentDate2 ? (`${('0' + ((new Date(currentDate2)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate2)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate2)).getFullYear()}`) : '..-..-..'}</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponseTimelineChart}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Evolution des timesheets entre les dates du {currentDate ? (`${('0' + ((new Date(currentDate)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate)).getFullYear()}`) : '..-..-..'} au {currentDate2 ? (`${('0' + ((new Date(currentDate2)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate2)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate2)).getFullYear()}`) : '..-..-..'}</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponseLine}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default TimesheetGlobal;
