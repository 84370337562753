import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import ProgressBar from "@ramonak/react-progress-bar";
import Login from './Components/Login/Login'
import Dashboard from './Components/Dashboard'
import UserBexio from './Components/UserBexio/Main'
import Timesheet from './Components/Timesheet/Main'
import TypeProjet from './Components/TypeProjet/Main'
import Projet from './Components/Projet/Main'
import TimesheetPersonne from './Components/Timesheet/TimesheetPersonne'
import TimesheetGlobal from './Components/Timesheet/TimesheetGlobal'
import TimesheetPackage from './Components/Timesheet/TimesheetPackage'
import ProjetHeure from './Components/Rapports/projet-heure'
import CoutHeureProjet from './Components/Rapports/cout-heure-projet'
import Weekly from './Components/Rapports/weekly'
import RapportGlobal from './Components/Rapports/rapport-global'
import OperationFinanciere from './Components/Finance/operation'
import Parametrage from './Components/Parametrage/Main'
import STORAGE from './lib/storage'
import SOCKET from './lib/socket';
import { message } from 'antd';
import API from './lib/api';

function App() {
  const [progressType, setProgressType] = useState('');
  const [openUrl, setOpenUrl] = useState('')
  const [pourcent, setPourcent] = useState(0);

  useEffect(() => {
    SOCKET.on("evolutionChargement", data => {
      setProgressType(data.type)
      setPourcent(data.pourcentage)
    });

    SOCKET.on("notification", data => {
      if(data.type == 'success') {
        message.success(data.valeur);
      }
      else {
        message.error(data.valeur);
      }
    });

    SOCKET.on('openurl', data => {
      console.log(`${API.BASE_URL}${API.PATH_FILE}${data}`)
      setOpenUrl(`${API.BASE_URL}${API.PATH_FILE}${data}`);
      // window.open(`${API.BASE_URL}${API.PATH_FILE}${data}`);
      // setTimeout(() => {
      //   setOpenUrl('');
      // }, 3000);
    })
  }, [])

  return (
    <>
      {
        pourcent != 0 && 
        <div className="progression">
          <h4>{progressType}</h4>
          <ProgressBar completed={pourcent}  width={'99%'} bgColor={'#ff0000'} />
        </div>
      }
      {
        openUrl != 0 && 
        <div className="progression">
          Si le rapport n'est pas automatiquement téléchargé,
          <a href={openUrl}>cliquez pour chargement</a>
        </div>
      }
      <Router >
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
                return (
                    STORAGE.isLogged ?
                    <Redirect to="/Dashboard" /> :
                    <Redirect to="/Login" /> 
                ) 
            }}
          />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/Dashboard" component={Dashboard} />
          <Route exact path="/UserBexio" component={UserBexio} />
          <Route exact path="/Timesheet" component={Timesheet} />
          <Route exact path="/TypeProjet" component={TypeProjet} />
          <Route exact path="/Projet" component={Projet} />
          <Route exact path="/Timesheet/personnes" component={TimesheetPersonne} />
          <Route exact path="/Timesheet/global" component={TimesheetGlobal} />
          <Route exact path="/Timesheet/package" component={TimesheetPackage} />
          <Route exact path="/Rapports/projets-heures" component={ProjetHeure} />
          <Route exact path="/Rapports/couts-heures/projet" component={CoutHeureProjet} />
          <Route exact path="/Rapports/weekly" component={Weekly} />
          <Route exact path="/Parametrages" component={Parametrage} />
          <Route exact path="/Rapports/global/" component={RapportGlobal} />
          <Route exact path="/Finance/operations" component={OperationFinanciere} />
        </Switch>


      </Router>
    </>
  );
}

export default App;
