import React, { useEffect, useState } from 'react'
import API from '../../lib/api';
import QUERY from '../../lib/query';
import DELETE from '../../lib/delete';
import Table from '../Table';
import profil from '../../assets/images/avatar.png';
import SOCKET from '../../lib/socket';

function Listes({setOpenCreate, setOpenEdit, openCreate, refresh, setRefresh, setUpdate, setNewInput}) {
  
  const [list, setList] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [size, setSize] = useState(100);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [action, setAction] = useState({});
  const [csvData, setCsvData] = useState([]);

  const headers= [
    "ID",
    "Nom",
    "Nombre de projet",
    "Actions"
  ]

  const initData = () => {
    QUERY.allFilteredTypeProjets({page, size, search})
    .then((data) => {
      console.log('data : ', data)
      setTotalRow(data && data._allTypeDeProjetsMeta && data._allTypeDeProjetsMeta.count ? data._allTypeDeProjetsMeta.count : 0)
      let list = data && data.allTypeDeProjets ? data.allTypeDeProjets.map(row => {
        return [
        {
          type: 'ID',
          value: row.id
        },
        {
          type: 'TEXT',
          value: row.id_bexio
        },
        {
          type: 'TEXT',
          value: row.name
        },
        {
          type: 'TEXT',
          value: row._projetsMeta && row._projetsMeta.count ? row._projetsMeta.count : 0
        },
        // {
        //   type: 'TEXT',
        //   value: row.email
        // },
        {
          type: 'ACTION',
          value: [
            // {
            //   type: 'edit',
            //   value: row.id
            // },
            {
              type: 'details',
              value: row.id
            },
            // {
            //   type: 'blocked',
            //   value: row.id
            // }
          ]
        }
      ]}) : [];
      setList(list);
    })
    .catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    initData();
    SOCKET.on("evolutionChargement", data => {
      initData();
    });
  }, []);

  useEffect(() => {
    console.log('page : ', page, ' size : ', size)
    initData();
  }, [page, size, search]);

  useEffect(() => {
    if(refresh) {
      initData();
      setRefresh(false);
    }
  }, [refresh])

  useEffect(() => {
    switch (action.type) {
      case 'remove':
        DELETE.DELETE('le joueur', 'User', action.value)
        .then(res => { initData(); }).catch(err => { initData(); });
        setAction({});
        break;

      case 'edit':
        console.log("edit", action);
        QUERY.getJoueurById({idJoueur: action.value})
        .then((data) => {
          setUpdate(data);
          setOpenEdit(true);
        })
        .catch((err) => {
          console.log(err);
        })
        setAction({});
        break;

      case 'details':
        console.log("details", action);
        QUERY.getTypeProjetById({id: action.value})
        .then((data) => {
          setUpdate(data);
          setOpenCreate(true);
        })
        .catch((err) => {
          console.log(err);
        })
        setAction({});
        break;

      default:
        break;
    }
  }, [action]);

  return (
    <Table typeSynchro={'TYPEPROJET'} pagination={true} page={page} size={size} setPage={setPage} search={search} setSearch={setSearch} setSize={setSize} totalPage={parseInt(totalRow%size == 0 ? (totalRow / size) : ((totalRow / size) + 1))} noAdd={true} csv={false} sync={true} csvData={csvData} title="Listes des types de projets" headers={headers} list={list} setOpenCreate={setOpenCreate} setOpenEdit={setOpenEdit} setNewInput={setNewInput} setAction={setAction}/>
  );
}

export default Listes;