import ALERT from './alert';
import MUTATION from './mutation';

const CREATE = async (titre, list, value, idUpdate, accordee = false) => {
    console.log('idUpdate : ', idUpdate)
    const res = await MUTATION.CREATE(idUpdate ? {list, value, idUpdate} : {list, value});
    if (res && res.id) {
        ALERT.alert('success', ((idUpdate ? 'Modification ' : 'Ajout ') + list), titre + (idUpdate ? (' a bien été ' + (accordee ? 'modifiée !' : 'modifié !')): ' a bien été ' + (accordee ? 'ajoutée !' : 'ajouté !')));
    }
    else {
        ALERT.alert('error', (idUpdate ? 'Erreur modification ' : 'Erreur ajout ') + list, (idUpdate ? "Erreur lors de la modification !" : "Erreur lors de l'ajout !"));
    }
    return res;
}

export default {
    CREATE
};