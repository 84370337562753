import React, { useState, useEffect } from 'react';
import profil from '../assets/images/avatar.png';
import { useForm } from "react-hook-form";
import API from '../lib/api';
import COLORS from '../lib/colors';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactLoading from 'react-loading';
import ReactPlayer from 'react-player/youtube'
import check from '../assets/images/check.png';
import uncheck from '../assets/images/uncheck.jpg';
import { Editor } from '@tinymce/tinymce-react';
import ALERT from '../lib/alert';
var QRCode = require('qrcode.react');


function Form({titre, openCreate, setOpenCreate, inputs, handleResult, list}) {
  const { register, handleSubmit, getValues, setValue, onload, watch, errors } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });
  const [ preview, setPreview ] = useState('')
  const [ previewVideo, setPreviewVideo ] = useState('')
  const [ previewPDF, setPreviewPDF ] = useState('');
  const [ typeQuestion, setTypeQuestion ] = useState('QCM');
  const [ challenge, setChallenge ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ fileChange, setFileChange ] = useState(false);
  const [ actif, setActif ] = useState(false);
  const [ actif2, setActif2 ] = useState(false);
  const [ leSaviezVous, setLeSaviezVous ] = useState('');
  const [ optionValueOfs, setOptionValueOfs ] = useState({});
  const [ selectedColor, setSelectedColor ] = useState(COLORS && COLORS[0] ? COLORS[0] : { name: "Gris clair", hex: "#D0D0D0" });
  const [ bonneReponses, setBonneReponses ] = useState([]);
  const [ isDefaultSet, setIsDefaultSet ] = useState(false);
  const [ qrcodeGenerated, setQrcodeGenerated ] = useState('');
  var onSubmit = data => {
    if (data.typeQuestion === 'QCM' && !(optionValueOfs && optionValueOfs.length > 0 && bonneReponses && bonneReponses.value)) {
        ALERT.alert('warning', "Le formulaire n'est pas completement rempli", "Les questions de type QCM doivent avoir des réponses proposées et une bonne réponse");
        return;
    }
    setLoading(true);
    handleResult({...data, reponseProposes: optionValueOfs, bonneReponses, actif, actif2, leSaviezVous, color: selectedColor.hex})
  };

  const handleChange = (newValue, actionMeta) => {
    setOptionValueOfs(newValue);
    var _bonneReponses = bonneReponses;
    const BR = (newValue.find(e => e.value === bonneReponses.value));
    if(!BR){
        _bonneReponses= {};
    }
    setBonneReponses(_bonneReponses)
    console.groupEnd();
  };

  const handleChangeBonneReponse = (newValue, actionMeta) => {
    setBonneReponses(newValue);
    console.groupEnd();
  };
  const handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  const handleClear = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  const handleEditorChange = (content, editor) => {
    setLeSaviezVous(content);
  }

  const downloadQRCode = (qrValue) => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrValue}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(()=> {
    const files = getValues('imageIcon');
    console.log(files)
    if (files && files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
            setPreview(e.target.result);
            // setPreviewPDF(e.target.result);
        }

        reader.readAsDataURL(files[0]); // convert to base64 string
    }
  }, [watch('imageIcon')], fileChange)

//   const handleIconImageChange = (files) => {
//     if (files && files[0]) {
//         var reader = new FileReader();

//         reader.onload = function(e) {
//             setPreview(e.target.result);
//             // setPreviewPDF(e.target.result);
//         }

//         reader.readAsDataURL(files[0]); // convert to base64 string
//     }
//   }

  useEffect(()=> {
    setPreviewVideo(getValues('videoYoutube'));
  }, [watch('videoYoutube')]);

  useEffect(() => {
    // if(inputs && inputs[0] && inputs[0].name === 'id') {
        setLoading(false);
        setPreview('');
        setPreviewVideo('');
        const _bonneReponses = inputs && inputs.find(e => e.name === 'bonneReponses') && inputs.find(e => e.name === 'bonneReponses').defaultValue ? inputs.find(e => e.name === 'bonneReponses').defaultValue : {};
        setBonneReponses(_bonneReponses);
        const _optionValueOfs = inputs && inputs.find(e => e.name === 'reponseProposees') && inputs.find(e => e.name === 'reponseProposees').defaultValue ? inputs.find(e => e.name === 'reponseProposees').defaultValue : [];
        setOptionValueOfs(_optionValueOfs);
        // const _typeQuestion = inputs && inputs.find(e => e.name === 'typeQuestion') ? inputs.find(e => e.name === 'typeQuestion').defaultValue : [];
        // setTypeQuestion(_typeQuestion);
        // const _challenge = inputs && inputs.find(e => e.name === 'challenge') ? inputs.find(e => e.name === 'challenge').defaultValue : '';
        // setChallenge(_challenge);
        const _check = inputs && inputs.find(e => e.name === 'actif') ? inputs.find(e => e.name === 'actif').defaultValue : false;
        const _check2 = inputs && inputs.find(e => e.name === 'actif2') ? inputs.find(e => e.name === 'actif2').defaultValue : false;
        const colorName = inputs && inputs.find(e => e.name === 'color') ? inputs.find(e => e.name === 'color').defaultValue : '';
        const _leSaviezVous = inputs && inputs.find(e => e.name === 'leSaviezVous') ? inputs.find(e => e.name === 'leSaviezVous').defaultValue : '';
        setLeSaviezVous(_leSaviezVous);
        console.log('colorName : ', colorName, COLORS);
        if (colorName && colorName !== '') {
            setSelectedColor(COLORS.find(e => e.hex === colorName) ? COLORS.find(e => e.hex === colorName) : {});
        }
        setActif(_check);
        setActif2(_check2);
        if(!isDefaultSet) {
            setIsDefaultSet(true)
        }
    // }
  }, [inputs]);

  return (
    <div className={openCreate ? 'form-add open': 'form-add'}>
      <h2>{titre}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        {
            inputs && inputs.map((input, indInput) => {
                switch (input.type) {
                    case 'id':
                        return  <div style={{display: 'none'}} key={indInput} className="form-text">
                                    <label>{input.libelle}</label>
                                    <input type="text" name={input.name} defaultValue={input.defaultValue ? input.defaultValue : null} disabled={input.disabled} ref={input.required ? register({ required: true }) : register} />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'text':
                        return  (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-text">
                                    <label>{input.libelle}</label>
                                    <input type="text" name={input.name} defaultValue={input.defaultValue ? input.defaultValue : null} disabled={input.disabled} ref={input.required ? register({ required: true }) : register} />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'tiny':
                        return  (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-text">
                                    <label>{input.libelle}</label>
                                    <Editor
                                        apiKey="40d0oks2mzhjlpyr7emqiwtddoqrqri85vqbrr8fjm8ze48z"
                                        initialValue={input.defaultValue ? input.defaultValue : ''}
                                        name={input.name}
                                        init={{
                                        height: 200,
                                        menubar: true,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | bold italic color backcolor underline | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'reponse':
                        return  (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-text">
                                    <label>{input.libelle}</label>
                                    <input type="text" name={input.name} onChange={(e) => setQrcodeGenerated(e.target.value)} defaultValue={input.defaultValue ? input.defaultValue : null} disabled={input.disabled} ref={input.required ? register({ required: true }) : register} />
                                    <div>
                                        <br/>
                                        <QRCode 
                                            id="qr-gen"
                                            value={qrcodeGenerated ? qrcodeGenerated : (input.defaultValue ? input.defaultValue : '')} 
                                            // renderAs="svg"
                                        />
                                        {
                                            (qrcodeGenerated || input.defaultValue) && 
                                            <p>
                                                <button className="QRCodeButton" type="button" onClick={e => downloadQRCode(qrcodeGenerated ? qrcodeGenerated : input.defaultValue)}>
                                                    Télécharger
                                                </button>
                                            </p>
                                        }
                                    </div>
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'number':
                        return  <div key={indInput} className="form-text">
                                    <label>{input.libelle}</label>
                                    <input type="number" step="0.01" disabled={input.disabled ? true : false} name={input.name} defaultValue={input.defaultValue ? input.defaultValue : null} disabled={input.disabled} ref={input.required ? register({ required: true }) : register} />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'color':
                        return  <div key={indInput} className="form-color">
                                    <label>{input.libelle}</label>
                                    <div className="selectedColor" style={{background: selectedColor.hex}}>
                                        <span>Coleur choisie : {selectedColor.name}</span>
                                    </div>
                                    <div className="bloc-color">
                                        {
                                            COLORS && COLORS.map((color, index) => (
                                                <div key={index} className="elts" style={{background: color.hex}}>
                                                    <a href="#" onClick={e => setSelectedColor(color)}>Choisir</a>
                                                    <span>{color.name}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                    case 'file':
                        return  <div key={indInput} className="form-file">
                                    <label>{input.libelle}</label>
                                    <input type="file" name={input.name} onChange={(e) => setFileChange(!fileChange)} disabled={input.disabled} ref={input.required ? register({ required: true }) : register} />
                                    <div>
                                        {
                                            (input.defaultValue || preview !== '') &&
                                            <>
                                                <br/>
                                                preview <span style={{display: 'none'}}>{preview}</span>
                                                <br/>
                                                <img className="preview" src={preview !== '' ? (preview) : (API.BASE_URL + API.PATH_FILE + input.defaultValue)} alt={input.name}/>
                                                {/* <button className="QRCodeButton" type="button" onClick={e => {
                                                    
                                                }}>
                                                    Supprimer la photo
                                                </button> */}
                                            </>
                                        }
                                    </div>
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'video':
                        return  <div key={indInput} className="form-text">
                                    <label>{input.libelle}</label>
                                    <input type="text" name={input.name} defaultValue={input.defaultValue ? input.defaultValue : ''} disabled={input.disabled} ref={input.required ? register({ required: true }) : register} />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                    <div>
                                        {
                                            (input.defaultValue || getValues('videoYoutube') !== '') &&
                                            <>
                                                <br/>
                                                preview
                                                <br/>
                                                <ReactPlayer url={getValues('videoYoutube')} />
                                            </>
                                        }
                                    </div>
                                </div>
                    case 'pdf':
                        return  <div key={indInput} className="form-file">
                                    <label>{input.libelle}</label>
                                    <input type="file" name={input.name} disabled={input.disabled} ref={input.required ? register({ required: true }) : register} />
                                    <div>
                                        {
                                            (input.defaultValue) &&
                                            <>
                                                <br/>
                                                existant : {input.defaultValue}
                                            </>
                                        }
                                    </div>
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'checkbox':
                        return <div key={indInput} className="form-check">
                                    <br/>
                                    <input type="checkbox" onChange={(e) => setActif(!actif)} checked={actif} name={input.name} disabled={input.disabled} /*defaultValue={input.defaultValue ? input.defaultValue : null}*/ id={input.name} ref={input.required ? register({ required: true }) : register} />
                                    <label htmlFor={input.name}>{input.libelle}</label>
                                </div>
                    case '_checkbox':
                        return <div key={indInput} className="form-check">
                                    <br/>
                                    <input type="checkbox" onChange={(e) => setActif2(!actif2)} checked={actif2} name={input.name} disabled={input.disabled} /*defaultValue={input.defaultValue ? input.defaultValue : null}*/ id={input.name} ref={input.required ? register({ required: true }) : register} />
                                    <label htmlFor={input.name}>{input.libelle}</label>
                                </div>
                    case 'select':
                        return (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-select">
                                    <label>{input.libelle}</label>
                                    <select multiple={input.multiple} name={input.name} id={input.name} disabled={input.disabled} defaultValue={input.defaultValue ? input.defaultValue : ''} ref={input.required ? register({ required: true }) : register}>
                                        {
                                            input && input.options && input.options.map((option, indOption) => (
                                                <option key={indOption} value={option.key}>{option.value}</option>
                                            ))
                                        }
                                    </select>
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'select-challenge':
                        return (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-select">
                                    <label>{input.libelle}</label>
                                    <select multiple={input.multiple} defaultValue={input.defaultValue ? input.defaultValue : ''} /* onChange={(e) => setChallenge(e.target.value)} value={challenge ? challenge : ''}*/ name={input.name} id={input.name} disabled={input.disabled} ref={input.required ? register({ required: true }) : register}>
                                        {
                                            input && input.options && input.options.map((option, indOption) => (
                                                <option key={indOption} value={option.key}>{option.value}</option>
                                            ))
                                        }
                                    </select>
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'select-typeQuestion':
                        return (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-select">
                                    <label>{input.libelle}</label>
                                    <select multiple={input.multiple} name={input.name} defaultValue={input.defaultValue ? input.defaultValue : 'QCM'} /*onChange={(e) => setTypeQuestion(e.target.value)} value={typeQuestion ? typeQuestion : 'QCM'} */ id={input.name} disabled={input.disabled}  ref={input.required ? register({ required: true }) : register}>
                                        {
                                            input && input.options && input.options.map((option, indOption) => (
                                                <option key={indOption} value={option.key}>{option.value}</option>
                                            ))
                                        }
                                    </select>
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'select-creatable':
                        return (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-select">
                                    <label>{input.libelle}</label>
                                    <CreatableSelect
                                        isClearable
                                        // name={input.name}
                                        isMulti
                                        value={optionValueOfs && optionValueOfs[0] ? optionValueOfs : (!isDefaultSet ? input.defaultValue : [])}
                                        clearValue={handleClear}
                                        ref={register}
                                        inputRef={input.required ? register({ required: true }) : register}
                                        defaultValue={input.defaultValue ? input.defaultValue : []}
                                        onChange={handleChange}
                                        // onInputChange={handleInputChange}
                                        options={input.options}
                                    />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'select-many':
                        return (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && <div key={indInput} className="form-select">
                                    <label>{input.libelle}</label>
                                    <Select
                                        isClearable
                                        // name={input.name}
                                        isMulti
                                        clearValue={handleClear}
                                        ref={register}
                                        inputRef={input.required ? register({ required: true }) : register}
                                        defaultValue={input.defaultValue ? input.defaultValue : []}
                                        onChange={handleChange}
                                        // onInputChange={handleInputChange}
                                        options={input.options}
                                    />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'select-reponse':
                        return (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) && ((input.valueOf && optionValueOfs && optionValueOfs[0]) || input.options) && <div key={indInput} className="form-select">
                                    <label>{input.libelle}</label>
                                    <Select
                                        options={input.valueOf && optionValueOfs && optionValueOfs[0] ? optionValueOfs : (input.options ? input.options : [])}
                                        isMulti={false}
                                        // name={input.name}
                                        value={bonneReponses && bonneReponses.label ? bonneReponses : (!isDefaultSet ? input.defaultValue : {})}
                                        onChange={handleChangeBonneReponse}
                                        id={input.name}
                                        ref={register}
                                        inputRef={input.required ? register({ required: true }) : register}
                                        disabled={input.disabled}
                                        defaultValue={input.defaultValue ? input.defaultValue : {}}
                                        clearValue
                                    />
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    case 'textarea':
                        return (!input.dependsOn || (input.dependsOn && watch(input.dependsOn.key) === input.dependsOn.value)) &&  <div key={indInput} className="form-text">
                                    <label>{input.libelle}</label>
                                    <textarea name={input.name} disabled={input.disabled} defaultValue={input.defaultValue ? input.defaultValue : null} ref={input.required ? register({ required: true }) : register}></textarea>
                                    {errors[input.name] && <span className="errorText">Le champs {input.name} est obligatoire</span>}
                                </div>
                    default:
                        break;
                }
            })
        }
        <div className="form-submit">
          {
              !loading && <>
                {
                    !(inputs && inputs[0] && inputs[0].name === 'id') && <input type="submit" name="ajouter" id="ajouter" value="Ajouter" className="ajouter" />
                }
                {
                    (inputs && inputs[0] && inputs[0].name === 'id') && <input type="submit" name="ajouter" id="ajouter" value="Modifier" className="ajouter" />
                }
                <input type="reset" name="" id="" value="Annuler" className="reset" onClick={e=> {
                    setOpenCreate(false);
                    setIsDefaultSet(false)
                }} />
              </>
          }
          {
              loading && <ReactLoading color={"#ff0000"} height={10} width={100} />
          }
        </div>
      </form>
        {
            list && list.map((l, indList) => (
                <div key={indList} className="form-text">
                    <label>
                        {l.header}
                        {
                            l.headerItems && l.headerItems.map((headerItem, index) => (
                                <span key={index} className={headerItem.icon}>  {headerItem.value}  </span>
                            ))
                        }
                    </label>
                    <ul>
                        {
                            l.items && l.items.map((row, indRow) => (
                                <li key={indRow}>
                                    {
                                        row.img &&
                                        <div className="icon-joueur">
                                            <img src={row.img !== '?' ? row.img : profil} className="avatar" alt="avatar" />
                                        </div>
                                    }
                                    <p>
                                        {row.value}
                                        {
                                            row.check &&<span style={{float: 'right'}}>  <img src={row.check === 'check' ? check : uncheck} className="check" alt="check" />  </span>
                                        }
                                    </p>
                                </li>
                            ))
                        }
                        {
                            !(l.items && l.items[0]) && <p style={{fontStyle: 'italic'}}> Aucun élément pour le moment ! </p>
                        }
                    </ul>
                </div>
            ))
        }
    </div>
  );
}

export default Form;
