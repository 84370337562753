import React, { useState } from 'react'
import QUERY from '../../lib/query';
import Details from '../Details'

function Form({update, openCreate, setOpenCreate}) {
  
  const [inputs, setInputs] = useState([
    {
      key: 'Id Bexio',
      value: update.id_bexio
    },
    {
      key: 'Nom',
      value: update.name
    },
    {
      key: 'Type',
      value: update.typeProject && update.typeProject.name ? update.typeProject.name : '--'
    },
    {
      key: 'Contact',
      value: update.contact && update.contact.name_1 ? update.contact.name_1 : '--'
    },
    {
      key: 'Date de debut',
      value: update.start_date ? (`${('0' + ((new Date(update.start_date)).getDate())).slice(-2)}-${('0' + ((new Date(update.start_date)).getMonth() + 1)).slice(-2)}-${(new Date(update.start_date)).getFullYear()}`) : '..-..-..',
    },
    {
      key: 'Budget',
      value: update.pr_invoice_type_amount ? (update.pr_invoice_type_amount + ' CHF') : '0 CHF'
    }
  ]);
  
  const [lists, setLists] = useState([]);
  const [invites, setInvites] = useState([]);
  const [bons, setBons] = useState(
    update.bonGagnes
  );

  // QUERY.getInvitedGamerByParrain({codeParrain: update.codeUser})
  // .then((invites) => {
  //   setInvites(invites)
  // })

  return (
    <Details titre="Détail du projet" lists={lists} inputs={inputs} setOpenCreate={setOpenCreate} openCreate={openCreate} bons={bons} invites={invites}/>
  );
}

export default Form;
