import { io } from "socket.io-client";
import API from './api';

const socketUrl = API.BASE_URL;

const SOCKET = io(socketUrl, { transports: ["websocket"] });

SOCKET.emit('synchro', 'start');


export default SOCKET