import React, { useState, useEffect } from 'react'
import profil from '../../assets/images/avatar.png';
import QUERY from '../../lib/query';
import API from '../../lib/api';
import MUTATION from '../../lib/mutation';
import CREATE from '../../lib/createItem'
import Form from '../Form';
import ALERT from '../../lib/alert';

function Edit({openCreate, setOpenCreate, setRefresh, update, setUpdate, newInput}) {

  const [inputs, setInputs] = useState([]);
  const [titre, setTitre] = useState('Ajouter un bon');
  const [list, setList] = useState([]);

  console.log(newInput);

  const handleResult = (data) => {
    console.log(data);
    if (!(data.password && data.password != '')) {
        CREATE.CREATE('le joueur', 'User', `{
            email: "${data.email}"
          }`,
          data.id ? data.id : null
        )
        .then((item) => {
          setRefresh(true);
          setOpenCreate(false);
        })
        .catch((err) => {
          console.log(err)
          setRefresh(true)
          setOpenCreate(false);
        })
    }
    else if (data.password === data.passwordConfirmation) {
        if (passwordValid(data.password)) {
            CREATE.CREATE('le joueur', 'User', `{
                email: "${data.email}",
                password: "${data.password}"
              }`,
              data.id ? data.id : null
            )
            .then((item) => {
              setRefresh(true);
              setOpenCreate(false);
            })
            .catch((err) => {
              console.log(err)
              setRefresh(true)
              setOpenCreate(false);
            })
        }
        else {
            ALERT.alert('error', 'Format mot de passe incorrect!', "Le mot de passe doit au moins être sur 8 caractères et doit contenir au moins une lette majuscule, ube lette minuscule, un chiffre et un caractère special!");
            setOpenCreate(false);
        } 
    }
    else {
        ALERT.alert('error', 'Mots de passe non conforment !', "Le mot de passe de confirmation n'es pas correct !");
        setOpenCreate(false);
    }
  }

  //Password validation
const passwordValid = (password) => { 
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const hasNumber = /\d/.test(password);
    const hasCapitalizedCharacter = /[A-Z]/.test(password);
    const hasSpecialChar = specialChars.test(password);
    
    if (hasNumber && hasCapitalizedCharacter && hasSpecialChar && password.length >= 8) {
    return true;
    } else {
    return false;
    }
}

  useEffect(() => {
    console.log(update);
    if (update && update.id) {
      setInputs([
        {
          type: 'id',
          name: 'id',
          defaultValue: update.id
        },
        {
          type: 'text',
          libelle: "Email",
          name: 'email',
          defaultValue: update.email,
          required: true
        },
        {
          type: 'text',
          libelle: "Nouveau mot de passe",
          name: 'password'
        },
        {
          type: 'text',
          libelle: "Confirmation du nouveau mot de passe",
          name: 'passwordConfirmation'
        }
      ])
      setUpdate({});
    }
  }, [update])

  return (
    <Form inputs={inputs} titre={titre} list={list} handleResult={handleResult} openCreate={openCreate} setOpenCreate={setOpenCreate}/>
  );
}

export default Edit;
