import React, { useEffect, useState } from 'react'
import Aside from '../Aside/Aside';
import QUERY from '../../lib/query';
import MUTATION from '../../lib/mutation';

import { message, Upload, Tabs, Space, Table, Tag, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DownloadOutlined, EyeOutlined, CloudUploadOutlined } from '@ant-design/icons';

import Lottie from 'react-lottie';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import templateImage from '../../assets/images/template.png';

import API from '../../lib/api';
import SOCKET from '../../lib/socket';

const CURRENT_URL = window.location.origin;

const uploadFile = '../../assets/images/upload.json';

const columns = [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Compte_Debit',
    dataIndex: 'Compte_Debit',
    key: 'Compte_Debit',
  },
  {
    title: 'Compte_Credit',
    dataIndex: 'Compte_Credit',
    key: 'Compte_Credit',
  },
  {
    title: 'Libelle',
    dataIndex: 'Libelle',
    key: 'Libelle',
  },
  {
    title: 'Montant',
    dataIndex: 'Montant',
    key: 'Montant',
    // render: (_, { Montant }) => {
    //   let color = 'black';
    //   return (
    //     <Tag color={color} key={Montant}>
    //       {Montant}
    //     </Tag>
    //   );
    // },
  },
  {
    title: 'Taxe',
    dataIndex: 'Taxe',
    key: 'Taxe',
  }
];

const { Dragger } = Upload;

function OperationFinanciere() {

    const [allChargements, setAllChargement] = useState([]);
    const [totaux, setTotaux] = useState({
      total: 0,
      success: 0,
      echec: 0
    })
    const [selected, setSelected] = useState('');
    const [tabShown, setTabShown] = useState('chargement');
    const [operations, setOperations] = useState([]);
    const [curentChargement, setCurentChargement] = useState({});
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      allOperationFinancieres();
      SOCKET.on('openurl', data => {
        allOperationFinancieres(true);
        setLoading(false);
      })
    }, []);

    const allOperationFinancieres = (autoSelect = false) => {
        QUERY.getAllChargements()
        .then((data) => {
            setAllChargement(data?.allOperationFinancieres);
            let total = 0;
            let success = 0;
            let echec = 0;
            data?.allOperationFinancieres?.forEach((operation) => {
              total += operation.operationTotal ? operation.operationTotal : 0;
              success += operation.operationChargees ? operation.operationChargees : 0;
              echec += operation.operationEchecs ? operation.operationEchecs : 0;
            });
            setTotaux({
              total,
              success,
              echec
            })
            if (autoSelect && data?.allOperationFinancieres && data?.allOperationFinancieres[0]) {
              handleFichierChnage(data?.allOperationFinancieres[0])
            }
        })
        .catch((err) => {})
    }

    const getDateFormat = (date) => {
        const _date = new Date(date);
        return `${_date.getDate()} ${getMonthLabel(_date.getMonth())} ${_date.getFullYear()}`
    } 

    const getMonthLabel = (month) => {
        switch(month) {
            case 0: return  'Jan'; 
            case 1: return  'Fev'; 
            case 2: return  'Mar'; 
            case 3: return  'Avr'; 
            case 4: return  'Mai'; 
            case 5: return  'Jui'; 
            case 6: return  'Jul'; 
            case 7: return  'Aou'; 
            case 8: return  'Sep'; 
            case 9: return  'Oct'; 
            case 10: return  'Nov'; 
            case 11: return  'Dec'; 
        }
    }

    const handleFichierChnage = (fichier) => {
      console.log(fichier);
      setSelected(fichier?.id);
      setTabShown('detail');
      setCurentChargement(fichier)
      QUERY.getOperationOfChargement(fichier?.id)
      .then((data) => {
        console.log(data);
        setOperations(data.map(val => {
          return {
            Date: getDateFormat(val?.date),
            Compte_Debit: val?.entreeOperations[0]?.accountDebit?.name,
            Compte_Credit: val?.entreeOperations[0]?.description,
            Libelle: val?.entreeOperations[0]?.accountDebit?.name,
            Montant: `${val?.entreeOperations[0]?.amount} ${val?.entreeOperations[0]?.devise?.name}`,
            Taxe: val?.entreeOperations[0]?.taxe?.value
          }
        }));
      })
      .catch((err) => {
      })
    }

    const props = {
        name: 'file',
        multiple: false,
        beforeUpload(file) {
          setLoading(true);
          MUTATION.OperationFincanciere(file)
          .then((data) => {
            console.log(data);
            // message.success(`${file.name} file uploaded successfully.`);
            // allOperationFinancieres();
          })
          .catch(err => {
            console.log(err)
            setLoading(false);
            message.error(`${file.name} file upload failed.`);
          });
          // if (status !== 'uploading') {
          //   // console.log(info.file, info.fileList);
          //   
          // }
          // if (status === 'done') {
          //   message.success(`${info.file.name} file uploaded successfully.`);
          // } else if (status === 'error') {
          //   message.error(`${info.file.name} file upload failed.`);
          // }
          return false;
        }
    };

  return (
    <>
        <Aside/>
        <div className="content-page">
      <h1>Tableau de bord</h1>
      <div className="full-width">
        <div className="bloc-stat">
          <div className="op-fin">
            <h2 style={{marginTop: 0, marginBottom: -15}}>{allChargements?.length}</h2>
            <h3>Fichiers chargés</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="op-fin">
            <h2 style={{marginTop: 0, marginBottom: -15}}>{totaux.total}</h2>
            <h3>Opérations</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="op-fin">
            <h2 style={{marginTop: 0, marginBottom: -15}}>{totaux.success}</h2>
            <h3>Op chargées</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="op-fin">
            <h2 style={{marginTop: 0, marginBottom: -15}}>{totaux.echec}</h2>
            <h3>Op non chargées</h3>
          </div>
        </div>

      </div>

      <div className="full-width">
        <div className="list-winner">
          <div className="content">
            <h2>Liste chargements</h2>
            <ul>
              {
                allChargements && allChargements.map((fichier, index) => (
                  <li key={index} onClick={e => { handleFichierChnage(fichier) }} className={selected == fichier.id ? "selected op-icon" : "op-icon"}>
                    <div className="img-profil">
                      <div className="timesheet" style={{background: selected == fichier.id ? "#FFF" : "#000"}}><strong>{fichier.operationTotal ? fichier.operationTotal : 0}</strong></div>
                    </div>
                    <div className={"nom-profil"}>
                        {getDateFormat(fichier.date)} : <strong><strong style={{color: "#16a085"}}>{fichier.operationChargees ? fichier.operationChargees : 0}</strong> réussis</strong> et <strong><strong style={{color: "#ff0000"}}>{fichier.operationEchecs ? fichier.operationEchecs : 0}</strong> échecs</strong> 
                        <div style={{float: 'right', marginTop: '-4px'}}>
                            <a id={fichier.id} style={{display: 'none'}} href={`${CURRENT_URL}${API.PATH_FILE}${fichier?.rapportChargement}`}></a>
                            {/* <EyeOutlined onClick={e => { handleFichierChnage(fichier) }} className='op-icon' style={{fontSize: '20px', marginRight: 10, color: '#000000'}}/> */}
                            <DownloadOutlined onClick={(e) => document.getElementById(fichier.id)?.click()} className='op-icon' style={{fontSize: '20px', marginRight: 40, color: '#FF0000'}}/>
                        </div>
                    </div>
                  </li>
                ))
              }
            </ul>
            <p className="total-gagnant total-operation">Total fichiers chargés: <strong>{allChargements?.length}</strong></p>
          </div>
        </div>

        <div className="list-winner col-md-8">
          <div className="content">
            <Tabs
              defaultActiveKey="chargement"
              activeKey={tabShown}
              onChange={(e) => {
                console.log(e);
                if (e == 'chargement') {
                  setTabShown(e);
                  setCurentChargement({});
                  setSelected('');
                  setOperations([]);
                }
              }}
              items={[CloudUploadOutlined, EyeOutlined].map((Icon, i) => {
                const id = i == 0 ? 'chargement' : 'detail';

                return {
                  label: (
                    <span>
                      <Icon />
                      {
                        i == 0 ? 'Déposez votre fichier' : 'Détail chargement'
                      }
                    </span>
                  ),
                  key: id,
                  disabled: i == 0 ? false : true,
                  children: (
                    <>
                      {
                        i == 0 ? 
                        <>
                          <h2 className='finance-title'>Chargement en masse des opérations financiéres</h2>
                          <h2 className='finance-title'>Déposez votre fichier</h2>
                          <div className="img-diagramme upload-content">
                          <Spin tip="Chargement en cours" spinning={loading} size="large">
                              <Dragger {...props}>
                                  <p className="ant-upload-drag-icon">
                                  {/* <InboxOutlined /> */}
                                  <Player
                                      autoplay
                                      loop
                                      src={"https://lottie.host/670d0246-a3f7-47d9-8517-ba7036d4f4ed/WOJwPojNXe.json"}
                                      style={{height: '250px'}}
                                      >
                                  </Player>
                                  </p>
                                  <p className="ant-upload-text">Cliquez ou faites glisser le fichier dans cette zone pour faire le chargement</p>
                                  <p className="ant-upload-hint">
                                    Veuillez respecter le format du template
                                    <img className='template-excel' src={templateImage}/>
                                  </p>
                              </Dragger>
                          </Spin>
                          </div>
                        </>
                        :
                        <>
                          <h2 className='finance-title'>Chargement du {getDateFormat(curentChargement.date)}</h2>
                          <div className="img-diagramme upload-content">
                            <Table columns={columns} dataSource={operations} />
                          </div>
                        </>
                      }
                    </>
                  ),
                };
              })}
            />
          </div>
        </div>
      </div>

    </div>
    </>
  );
}

export default OperationFinanciere;
