import React, { useState, useEffect } from 'react'
import profil from '../../assets/images/avatar.png';
import QUERY from '../../lib/query';
import API from '../../lib/api';
import MUTATION from '../../lib/mutation';
import CREATE from '../../lib/createItem'
import Form from '../Form';
import ALERT from '../../lib/alert';

function Edit({openCreate, setOpenCreate, setRefresh, update, setUpdate, newInput}) {

  const [inputs, setInputs] = useState([]);
  const [titre, setTitre] = useState('Metre à jour le coût de ' + update.firstname + ' ' + update.lastname);
  const [list, setList] = useState([]);

  console.log(newInput);

  const handleResult = (data) => {
    console.log(data)
    CREATE.CREATE('le coût', 'UserBexio', `{
        cout: ${data.cout},
        isHidden: ${data.actif}
        exclureFraisIndirect: ${data.actif2}
      }`,
      data.id ? data.id : null
    )
    .then((item) => {
      setRefresh(true);
      setOpenCreate(false);
    })
    .catch((err) => {
      console.log(err)
      setRefresh(true)
      setOpenCreate(false);
    })
  }

  //Password validation
const passwordValid = (password) => { 
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const hasNumber = /\d/.test(password);
    const hasCapitalizedCharacter = /[A-Z]/.test(password);
    const hasSpecialChar = specialChars.test(password);
    
    if (hasNumber && hasCapitalizedCharacter && hasSpecialChar && password.length >= 8) {
    return true;
    } else {
    return false;
    }
}

  useEffect(() => {
    console.log(update);
    if (update && update.id) {
      setInputs([
        {
          type: 'id',
          name: 'id',
          defaultValue: update.id
        },
        {
          type: 'text',
          libelle: "Prénom",
          name: 'firstname',
          defaultValue: update.firstname,
          required: false,
          disabled: true
        },
        {
          type: 'text',
          libelle: "Nom",
          name: 'lastname',
          defaultValue: update.lastname,
          required: false,
          disabled: true
        },
        {
          type: 'text',
          libelle: "Email",
          name: 'email',
          defaultValue: update.email,
          required: false,
          disabled: true
        },
        {
          type: 'number',
          libelle: "Coût",
          name: 'cout',
          defaultValue: update.cout,
          required: true
        },
        {
          type: 'checkbox',
          libelle: "Masquer l'utilisateur dans les calculs",
          name: 'actif',
          defaultValue: update.isHidden,
          required: false
        },
        {
          type: '_checkbox',
          libelle: "Exclure les frais indirect",
          name: 'actif2',
          defaultValue: update.exclureFraisIndirect,
          required: false
        }
      ])
      setUpdate({});
    }
  }, [update])

  return (
    <Form inputs={inputs} titre={titre} list={list} handleResult={handleResult} openCreate={openCreate} setOpenCreate={setOpenCreate}/>
  );
}

export default Edit;
