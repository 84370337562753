import { addDays, startOfWeek, endOfWeek } from 'date-fns';

const getToken = () => {
  return localStorage.getItem('token');
}

const setToken = (token) => {
      localStorage.setItem('token', token);
}

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
}

const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
}

const isLogged = () => {
  return getToken() ? true : false
}

const loggout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}

const setDateRange = (date1, date2) => {
  sessionStorage.setItem('daterange', JSON.stringify({
      start: date1,
      end: date2
  }))
}

const getDateRange = () => {
  return JSON.parse(sessionStorage.getItem('daterange'));
}

const getDefaultRange = () => {
  var date1 = new Date(addDays(new Date(), -8));
  date1.setHours(0);
  date1.setMinutes(0);
  date1.setSeconds(0);
  var date2 = new Date(addDays(new Date(), -1));
  date2.setHours(23);
  date2.setMinutes(59);
  date2.setSeconds(59);
  setDateRange(date1, date2);
  return {
    start: date1,
    end: date2
  }
}

export default {
    getToken,
    setToken,
    getUser,
    setUser,
    isLogged,
    loggout,
    getDateRange,
    setDateRange,
    getDefaultRange
}