import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import QUERY from '../../lib/query';
import Aside from '../Aside/Aside';
import { Editor } from '@tinymce/tinymce-react';

import Lottie from 'react-lottie';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ALERT from '../../lib/alert'

import STORAGE from '../../lib/storage';
const processFile = '../../assets/images/process.json';

require("highcharts/modules/timeline")(Highcharts);

if (typeof Highcharts === 'object') {
    highcharts3d(Highcharts)
    HighchartsExporting(Highcharts)
}

const options = [
    { label: 'Thing 1', value: 1},
    { label: 'Thing 2', value: 2},
];

function TimesheetGlobal() {
    const [mailSent, setMailSent] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDate2, setCurrentDate2] = useState(new Date());
    const [destinataire, setDestinataire] = useState('dev@pulse.digital');
    const [projets, setProjets] = useState([]);
    const [allProjets, setAllProjets] = useState([]);
    const [currentProjets, setCurrentProjets] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [currentContacts, setCurrentContacts] = useState([]);
    const [copyEmail, setCopyEmail] = useState('');
    const [subject, setSubject] = useState(`Rapport global du ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()} pour ${currentProjets.length} projet(s)`);
    const [mailContent, setMailContent] = useState(`
        Bonsoir <strong>PULSE.digital</strong>, 
        <br/>
        Bexio manager vous fait parvenir le global du <b> ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()} </b><br/>
        Le rapport concerne <b>${currentProjets.length} projet(s)<b/><br/>
        Bonne fin de weekend et à la prochaine!
    `);

    const handleEditorChange = (content, editor) => {
        setMailContent(content);
    }

    const sendEmail = () => {
        let projetIds = currentProjets.filter(val => !Object.is(val.value, '*')).map((projet) => `"${projet.value}"`)
        var date1 = currentDate;
        date1.setHours(0);
        date1.setMinutes(0);
        date1.setSeconds(0);
        var date2 = currentDate2;
        date2.setHours(23);
        date2.setMinutes(59);
        date2.setSeconds(59);
        setCurrentDate(date1);
        setCurrentDate2(date2);
        setMailSent(true);
        QUERY.sendGlobal({
            email: destinataire,
            debut: currentDate.toISOString(),
            fin: currentDate2.toISOString(),
            cc_email: copyEmail,
            objet_email: subject,
            content_email: mailContent,
            projetIds: projetIds
        }).then((param) => {
            // setMailSent(true);
        })
        .catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        var date = STORAGE.getDateRange();
        if (!date) {
            date = STORAGE.getDefaultRange();
        }
        const date2 = new Date(date.end);
        setCurrentDate2(date2);
        QUERY.GetEmailAdministration().then((param) => {
            param && param[0] && setDestinataire(param[0].valeur);
        })
        .catch(err => {
            console.log(err);
        });
        QUERY.getFirstTimesheetDate().then((dateTimeSheet) => {
            console.log(dateTimeSheet && dateTimeSheet[0] && dateTimeSheet[0].date ? dateTimeSheet[0].date : new Date());
            var date1 = new Date(dateTimeSheet && dateTimeSheet[0] && dateTimeSheet[0].date ? dateTimeSheet[0].date : '');
            date1.setHours(0);
            date1.setMinutes(0);
            date1.setSeconds(0);
            setCurrentDate(date1);
        })
        .catch(err => {
            console.log(err);
        });
        QUERY.allProjets()
        .then(data => {
            const projets = data.map(d => ({
                label: d.name,
                value: d.id,
                contactId: d.contact.id
            }));
            setAllProjets([
            {
                label: "TOUT", 
                value: "*"
            },
            ...projets]);
            setCurrentProjets([])
        });


        QUERY.allContactsWithProject()
        .then(data => {
            const contacts = data.map(d => ({
                label: d.name_1,
                value: d.id
            }));
            setContacts([
            {
                label: "TOUT", 
                value: "*"
            },
            ...contacts]);
            setCurrentContacts([])
        });
    }, []);

    useEffect(() => {
        setMailContent(`
            Bonsoir <strong>PULSE.digital</strong>, 
            <br/>
            Bexio manager vous fait parvenir le global du <b> ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()} </b><br/>
            Le rapport concerne <b>${currentProjets.length} projet(s)<b/><br/>
            Bonne fin de weekend et à la prochaine!
        `);
        setSubject(`Rapport global du ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()} pour ${currentProjets.length} projet(s)`)
    }, [currentDate, currentDate2, currentProjets]);

    const handleDateChange = (date) => {
        setCurrentDate(date[0]);
        setCurrentDate2(date[1]);
        // STORAGE.setDateRange(date[0], date[1])
    }

    const handleContactChanges = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            setCurrentContacts(contacts);
            handleFilterProjectsOfContacts(contacts);
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setCurrentContacts([]);
            handleFilterProjectsOfContacts([]);
        } else if (event.action === "deselect-option") {
            setCurrentContacts(value.filter(o => o.value !== "*"));
            handleFilterProjectsOfContacts(value.filter(o => o.value !== "*"));
        } else if (value.length === contacts.length - 1) {
            setCurrentContacts(contacts);
            handleFilterProjectsOfContacts(contacts);
        } else {
            setCurrentContacts(value);
            handleFilterProjectsOfContacts(value);
        }
    }

    const handleFilterProjectsOfContacts = (_contacts) => {
        let projets = allProjets.filter(allPr => _contacts.find(contact => allPr.contactId == contact.value));
        if (projets && projets[0]) {
            projets = [
                {
                    label: "TOUT", 
                    value: "*"
                },
                ...projets
            ]
        }
        setProjets(projets);
        let currentProjects = currentProjets.filter(pr => _contacts.find(contact => pr.contactId == contact.value));
        setCurrentProjets(currentProjects);
    }

    const handleProjetChanges = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            setCurrentProjets(projets);
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setCurrentProjets([]);
        } else if (event.action === "deselect-option") {
            setCurrentProjets(value.filter(o => o.value !== "*"));
        } else if (value.length === projets.length - 1) {
            setCurrentProjets(projets);
        } else {
            setCurrentProjets(value);
        }
    }

  return (
    <>
        <Aside/>
        <div className="content-page">
            <h1>Envoyer un rapport de global</h1>

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={contacts} 
                onChange={handleContactChanges}
                value={currentContacts}
            />

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={projets} 
                onChange={handleProjetChanges}
                value={currentProjets}
            />

            <DateRangePicker className="time-picker"
                onChange={e => handleDateChange(e)}
                value={[currentDate, currentDate2]}
            />

            {/* <DatePicker className="time-picker" 
                onChange={handleDateChange} 
                value={currentDate2} 
            /> */}

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content" style={{'padding': '90px'}}>
                        {
                            mailSent &&
                            <div>
                                {/* <Lottie 
                                    options={defaultOptions}
                                    height={400}
                                    width={400}
                                /> */}
                                <h3>Bonjour, Je suis Jarvis,</h3>
                                <h3>Je suis l'IA de Pulse.digital, ravis d'être l'intelligence artificielle qui vous prépare votre rapport. Le mail est en cours de préparation. </h3>
                                <Player
                                    autoplay
                                    loop
                                    src="https://bexio-manager.prodn.pulsweb.ch/uploads/process.json"
                                    style={{ height: '550px', width: '900px' }}
                                    >
                                </Player>
                                <p style={{'float': 'right', 'fontWeight': 'bold'}}>Merci et à la prochaine !</p>
                            </div>
                        }
                        {
                            !mailSent && 
                            <div className="content-email">
                                <h2 className='header-email'>Nouveau rapport global</h2>
                                <div className="img-diagramme">
                                    <div className="form-add email-form" style={{'width': '100%', 'height': 'auto', 'right': '0%', 'position': 'relative'}}>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>From : </label>
                                            <input type="text" name='valeur' disabled value="jarvis@pulse.digital"/>
                                        </div>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>To : </label>
                                            <input type="text" name='valeur' value={destinataire} onChange={e => setDestinataire(e.target.value)}/>
                                        </div>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>CC : </label>
                                            <input value={copyEmail} onChange={e => setCopyEmail(e.target.value)} type="text" name='valeur'/>
                                        </div>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>Subject : </label>
                                            <input type="text" name='valeur' value={subject} onChange={e => setSubject(e.target.value)}/>
                                        </div>
                                        <Editor
                                            apiKey="40d0oks2mzhjlpyr7emqiwtddoqrqri85vqbrr8fjm8ze48z"
                                            initialValue={mailContent}
                                            value={mailContent}
                                            name='content'
                                            init={{
                                            height: 200,
                                            menubar: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic color backcolor underline | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />

                                        <div className="form-submit" style={{'float': 'right'}}>
                                            <input onClick={e => sendEmail()} style={{'padding': '0px 0px', 'fontSize': '40px'}} type="submit" name="ajouter" id="ajouter" value="💌" className="ajouter sub" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    </>
  );
}

export default TimesheetGlobal;
