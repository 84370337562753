import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting'
import { CSVLink, CSVDownload } from "react-csv";
import download from '../../assets/images/download.png';
import QUERY from '../../lib/query';

if (typeof Highcharts === 'object') {
  highcharts3d(Highcharts)
  HighchartsExporting(Highcharts)
}

function Accueil() {
  const [totals, setTotals] = useState({});
  const [totalInactifs, setTotalInactifs] = useState({});
  const [userBexios, setUserBexios] = useState([]);
  const [projets, setProjets] = useState([]);





  const [csvData, setCsvData] = useState([]);
  const [reponseChart, setReponseChart] = useState(null);

  useEffect(() => {
    // Totals
    QUERY.getTotals()
    .then((totals) => {
      setTotals(totals);
    })
    .catch((err) => {
      console.log(err);
    })

    QUERY.getTotalInactifs()
    .then((totals) => {
      setTotalInactifs(totals);
    })
    .catch((err) => {
      console.log(err);
    })

    // Users Bexio
    QUERY.getUserBexios()
    .then((users) => {
      setUserBexios(users);
    })
    .catch((err) => {
      console.log(err);
    })

    // timesheets by project for charts
    QUERY.getTimesheetByProject()
    .then((projets) => {
      setProjets(projets);
      let data = [];
      let _csvData = [
        [
          'Id Bexio',
          'Nom Projet',
          'Nombre de Timesheet'
        ]
      ];
      let categories = projets.map((projet, index) => {
        _csvData.push([
          projet.id_bexio,
          projet.name,
          (projet._timesheetsMeta && projet._timesheetsMeta.count) ? projet._timesheetsMeta.count : 0
        ]);
        data.push(projet && projet._timesheetsMeta && projet._timesheetsMeta.count ? projet._timesheetsMeta.count : 0);
        return projet.name
      })
      setCsvData(_csvData)
      setReponseChart({
        chart: {
            renderTo: 'container',
            type: 'column',
            // options3d: {
            //     enabled: true,
            //     alpha: 15,
            //     beta: 15,
            //     depth: 50,
            //     viewDistance: 25
            // }
        },
        title: {
            text: null
        },
        xAxis: {
          categories,
          labels: {
              // skew3d: true,
              style: {
                  fontSize: '16px'
              }
          }
        },
        yAxis: {
            title: {
                text: null
            }
        },
        plotOptions: {
            column: {
                depth: 25
            }
        },
        series: [{
            data
        }]
    })
    })
    .catch((err) => {
      console.log(err);
    })

  }, [])

  const downloadCSV = () => {
    setTimeout(() => {
      document.getElementById('downloadElement').click();
    }, 200);
  }

  return (
    <div className="content-page">
      <h1>Tableau de bord</h1>
      <div className="full-width">
        <div className="bloc-stat">
          <div className="content">
            <h2 style={{marginTop: 0, marginBottom: -15}}>{totals && totals._allUserBexiosMeta && totals._allUserBexiosMeta.count ? totals._allUserBexiosMeta.count : 0} actifs</h2>
            <h2 style={{marginTop: 0, marginBottom: -5}}>{totalInactifs && totalInactifs._allUserBexiosMeta && totalInactifs._allUserBexiosMeta.count ? totalInactifs._allUserBexiosMeta.count : 0} inactifs</h2>
            <h3>Total Utilisateurs Bexio</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <h2 style={{marginTop: 0, marginBottom: -15}}>{totals && totals._allProjetsMeta && totals._allProjetsMeta.count ? totals._allProjetsMeta.count : 0} actifs</h2>
            <h2 style={{marginTop: 0, marginBottom: -5}}>{totalInactifs && totalInactifs._allProjetsMeta && totalInactifs._allProjetsMeta.count ? totalInactifs._allProjetsMeta.count : 0} inactifs</h2>
            <h3>Total Projets</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <h1>{totals && totals._allTimesheetsMeta && totals._allTimesheetsMeta.count ? totals._allTimesheetsMeta.count : 0}</h1>
            <h3>Total Timesheets</h3>
          </div>
        </div>

        <div className="bloc-stat">
          <div className="content">
            <h1>{totals && totals._allUsersMeta && totals._allUsersMeta.count ? totals._allUsersMeta.count : 0}</h1>
            <h3>Total Admins</h3>
          </div>
        </div>

      </div>

      <div className="full-width">
        <div className="list-winner">
          <div className="content">
            <h2>Liste des Utilisateurs Bexio</h2>
            <ul>
              {
                userBexios && userBexios.map((userBexio, index) => (
                  <li key={userBexio.id}>
                    <div className="img-profil">
                      <div className="timesheet">{userBexio && userBexio._timesheetsMeta && userBexio._timesheetsMeta.count ? userBexio._timesheetsMeta.count : '00'}</div>
                    </div>
                    <div className="nom-profil" >{userBexio.firstname} {userBexio.lastname} : <strong><strong style={{color: "#ff0000"}}>{userBexio.cout}</strong> CHF/H</strong></div>
                  </li>
                ))
              }
            </ul>
            <p className="list-footer">Total Utilisateurs: <strong>{userBexios.length}</strong></p>
          </div>
        </div>

        <div className="list-winner col-md-8">
          <div className="content">
            <h2>Nombre de timesheets par projet</h2>
            <div className="img-diagramme">
              <HighchartsReact
                highcharts={Highcharts}
                options={reponseChart}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="full-width">

        {/* <div className="list-winner">
          <div className="content">
            <h2>Questions par challenge</h2>
            <div className="img-diagramme">
              <HighchartsReact
                highcharts={Highcharts}
                options={questionsByChallengeChart}
              />
            </div>
          </div>
        </div> */}

        <div className="list-winner">
          <div className="content">
            <h2>Liste des projects</h2>
            <ul>
              {
                projets && projets.map((projet, index) => (
                  <li key={index}>
                    <div className="img-profil">
                    <div className="timesheet">{projet && projet._timesheetsMeta && projet._timesheetsMeta.count ? projet._timesheetsMeta.count : '00'}</div>
                    </div>
                    <div className="nom-profil">{projet.name ? (projet.name.length > 40 ? (projet.name.substr(0,40) + '..') : projet.name) : ''}</div>
                  </li>
                ))
              }
            </ul>
            <p className="list-footer">
              Total projets: <strong>{projets.length}</strong> 
              <img style={{width: '40px', float: "right", cursor: 'pointer'}} onClick={e=> {
                    downloadCSV();
                }}
                src={download} 
              />
            </p>
          </div>
        </div>

        {
            csvData && <CSVLink id="downloadElement" data={csvData}></CSVLink>
        }

        {/* <div className="list-winner">
          <div className="content">
            <h2>Joueurs par challenge</h2>
            <div className="mg-diagramme">
              <HighchartsReact
                highcharts={Highcharts}
                options={joueursByChallengeChart}
              />
            </div>
          </div>
        </div> */}
      </div>



      {/* <div className="full-width">

        <div className="list-winner">
          <div style={{textAlign: 'center', background: '#000', color: 'white'}} className="content">
            <h2>Total des bons débloqués</h2>
            <div className="img-diagramme">
              <h1>{totalBonDisponible}</h1>
            </div>
          </div>
        </div>

        <div className="list-winner">
          <div style={{textAlign: 'center', background: '#000', color: 'white'}} className="content">
            <h2>Total des bons activés</h2>
            <div className="img-diagramme">
              <h1>{totalGagant}</h1>
            </div>
          </div>
        </div>

        <div className="list-winner">
          <div style={{textAlign: 'center', background: '#000', color: 'white'}} className="content">
            <h2>Total des bons non activés</h2>
            <div className="img-diagramme">
              <h1>{totalBonDisponible - totalGagant}</h1>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="full-width">
        <div className="list-winner col-md-12">
          <div className="content">
            <h2>Les bons</h2>
            <div className="img-diagramme">
              <img src={diagramme2} className="avatar" alt="avatar" />
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
}

export default Accueil;
