import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import QUERY from '../../lib/query';
import Aside from '../Aside/Aside';
import STORAGE from '../../lib/storage';

require("highcharts/modules/timeline")(Highcharts);

if (typeof Highcharts === 'object') {
    highcharts3d(Highcharts)
    HighchartsExporting(Highcharts)
    
}

const options = [
    { label: 'Thing 1', value: 1},
    { label: 'Thing 2', value: 2},
];

function TimesheetGlobal() {
    const [reponsePieChart, setReponsePieChart] = useState(null);
    const [reponseChart, setReponseChart] = useState(null);
    const [reponseTimelineChart, setReponseTimelineChart] = useState(null);
    const [reponseLine, setReponseLine] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDate2, setCurrentDate2] = useState(new Date());
    const [projets, setProjets] = useState([]);
    const [users, setUsers] = useState([]);
    const [currentUsers, setCurrentUsers] = useState([]);
    const [currentProjets, setCurrentProjets] = useState([]);
    const [projetHeuresList, setProjetHeuresList] = useState([]);

    useEffect(() => {
        var date = STORAGE.getDateRange();
        if (!date) {
            date = STORAGE.getDefaultRange();
        }
        const date1 = date.start;
        const date2 = date.end;

        setCurrentDate(date1);
        setCurrentDate2(date2);
        QUERY.allProjets()
        .then(data => {
            const projets = data.map(d => ({
                label: d.name,
                value: d.id
            }));
            setProjets([{
                label: "TOUT", 
                value: "*"
            },
            ...projets]);
            setCurrentProjets([{
                label: "TOUT", 
                value: "*"
            },
            ...projets])

            QUERY.allUserBexios()
            .then(data => {
                const users = data.map(d => ({
                    label: d.firstname + ' ' + d.lastname,
                    value: d.id
                }));
                setUsers([{
                    label: "TOUT", 
                    value: "*"
                },
                ...users]);
                setCurrentUsers([{
                    label: "TOUT", 
                    value: "*"
                },
                ...users])
                initDate(date1, date2, projets, users)
            })
            .catch(err => {
            })
        })
        .catch(err => {
        })
    }, [])

    const handleDateChange = (date) => {
        console.log(date)
        setCurrentDate(date[0]);
        setCurrentDate2(date[1]);
        initDate(date[0], date[1], currentProjets, currentUsers)
        STORAGE.setDateRange(date[0], date[1])
    }

    const handleUserChanges = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            setCurrentUsers(users);
            initDate(currentDate, currentDate2, currentProjets, users)
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setCurrentUsers([]);
            initDate(currentDate, currentDate2, currentProjets, [])
        } else if (event.action === "deselect-option") {
            setCurrentUsers(value.filter(o => o.value !== "*"));
            initDate(currentDate, currentDate2, currentProjets, value.filter(o => o.value !== "*"))
        } else if (value.length === users.length - 1) {
            setCurrentUsers(users);
            initDate(currentDate, currentDate2, currentProjets, users)
        } else {
            setCurrentUsers(value);
            initDate(currentDate, currentDate2, currentProjets, value)
        }
    }

    const handleProjetChanges = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            setCurrentProjets(projets)
            initDate(currentDate, currentDate2, projets, currentUsers)
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setCurrentProjets([]);
            initDate(currentDate, currentDate2, [], currentUsers)
        } else if (event.action === "deselect-option") {
            setCurrentProjets(value.filter(o => o.value !== "*"));
            initDate(currentDate, currentDate2, value.filter(o => o.value !== "*"), currentUsers)
        } else if (value.length === projets.length - 1) {
            setCurrentProjets(projets);
            initDate(currentDate, currentDate2, projets, currentUsers)
        } else {
            setCurrentProjets(value);
            initDate(currentDate, currentDate2, value, currentUsers)
        }
    }

    
    const initDate = (date, date2, projetIds, userIds) => {
        var _projetIds = '[';
        var _userIds = '[';
        projetIds.forEach(projet => {
            if (projet.value != "*") {
                _projetIds += `"${projet.value}" `;
            }
        });
        userIds.forEach(user => {
            if (user.value != "*") {
                _userIds += `"${user.value}" `;
            }
        });
        _projetIds += ']';
        _userIds += ']';
        QUERY.getUserTimesheetFilter({date, date2, projetIds: _projetIds, userIds: _userIds})
        .then((res) => {
            const users = res.allUserBexios;
            const projets = res.allProjets;
            console.log(res);
            let data = []; 
            let dataFull = [];
            let dataNegatif = []; 
            let dataProjet = [];
            let dataTimeline = [];
            let categories = users.map((user, index) => {
                var duree = 0;
                user && user.timesheets && user.timesheets.forEach(timesheet => {
                    console.log(timesheet.duration)
                    const heureMinute = timesheet.duration && timesheet.duration != 'null' ? timesheet.duration.split(':') : ['0','0'];
                    duree += (parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60).toFixed(2)));
                    // console.log('Projet : ', timesheet.projet.name , ' duree : ', (parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60).toFixed(2))), ' total : ', duree);
                });
                dataFull.push({
                    name: user ? (user.firstname + ' ' + user.lastname) : '',
                    duree
                })
                return user ? (user.firstname + ' ' + user.lastname) : ''
            })
            dataFull = dataFull.sort((x,y) => y.duree-x.duree);
            categories = [];
            dataFull.forEach(d => {
                categories.push(d.name)
                data.push(d.duree);
                dataNegatif.push(parseFloat((d.duree - 40).toFixed(2)));
            });
            let categorieProjets = projets.map((projet, index) => {
                var duree = 0;
                var userTimesheets = [];
                projet && projet.timesheets && projet.timesheets.forEach(timesheet => {
                    const heureMinute = timesheet.duration ? timesheet.duration.split(':') : ['0','0'];
                    const currentDuree = (parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60).toFixed(2)));
                    duree += currentDuree;
                    const usr = userTimesheets.find(user => timesheet && timesheet.utilisateur && user.id === timesheet.utilisateur.id);
                    if (usr) {
                        usr.duree += currentDuree;
                    }
                    else {
                        userTimesheets.push({
                            name: timesheet && timesheet.utilisateur ? (timesheet.utilisateur.firstname + ' ' + timesheet.utilisateur.lastname) :  '----- -----',
                            duree: currentDuree,
                            id: timesheet && timesheet.utilisateur ? timesheet.utilisateur.id : ''
                        })
                    }
                });
                dataProjet.push([(projet ? projet.name : ''), duree]);
                var description = '';
                userTimesheets && userTimesheets.forEach((timeline, index) => {
                    description += ((index != 0 ? ' - ' : '') + (timeline.name + ' : ' + timeline.duree + ' heure(s)')) 
                });
                dataTimeline.push({
                    name: (projet ? projet.name : ''),
                    label: (currentDate ? (`${('0' + ((new Date(currentDate)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate)).getFullYear()}`) : '..-..-..') + ' ; ' + duree + ' heure(s)',
                    description
                })
                return projet.name
            });

            setProjetHeuresList(dataProjet.sort((x,y) => y[1]-x[1]));


            setReponseChart({
                    chart: {
                        type: 'column',
                    },
                    xAxis: {
                        categories
                    },
                    title: {
                        text: null
                    },
                    plotOptions: {
                        series: {
                            depth: 25,
                            colorByPoint: true
                        }
                    },
                    series: [{
                        data: data,
                        showInLegend: false
                    },
                    {
                        data: dataNegatif,
                        showInLegend: false
                    }
                ]
            });

            setReponsePieChart({
                chart: {
                    type: 'pie',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    options3d: {
                        enabled: true,
                        alpha: 45,
                        beta: 0
                    }
                },
                title: {
                    text: null
                },
                accessibility: {
                    point: {
                        valueSuffix: 'heure(s)'
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}heure(s) -> {point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        depth: 35,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Timesheet',
                    data: dataProjet
                }]
            });

            setReponseTimelineChart({
                chart: {
                    type: 'timeline'
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: '<h5>{chartTitle}</h5>' +
                            '<div>{typeDescription}</div>' +
                            '<div>{chartSubtitle}</div>' +
                            '<div>{chartLongdesc}</div>' +
                            '<div>{viewTableButton}</div>'
                    },
                    point: {
                        valueDescriptionFormat: '{index}. {point.label}. {point.description}.'
                    }
                },
                xAxis: {
                    visible: false
                },
                yAxis: {
                    visible: false
                },
                title: {
                    text: null
                },
                series: [{
                    data: dataTimeline
                }]
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }

  return (
    <>
        <Aside/>
        <div className="content-page">
            <h1>Projets et heures travaillées</h1>

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={users} 
                onChange={handleUserChanges}
                value={currentUsers}
            />

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={projets} 
                onChange={handleProjetChanges}
                value={currentProjets}
            />

            <DateRangePicker className="time-picker"
                onChange={e => handleDateChange(e)}
                value={[currentDate, currentDate2]}
            />

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Heures travaillées et supplémentaires / négatives</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponseChart}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width">
                <div className="list-winner">
                    <div className="content">
                        <h2>Projects & Heures</h2>
                        <ul>
                        {
                            projetHeuresList && projetHeuresList.map((projet, index) => (
                            <li key={index}>
                                <div className="img-profil">
                                <div className="timesheet">{projet && projet[1] ? parseInt(projet[1]) : '00'}</div>
                                </div>
                                <div className="nom-profil">{projet && projet[0] ? (projet[0].length > 40 ? (projet[0].substr(0,40) + '..') : projet[0]) : ''}</div>
                            </li>
                            ))
                        }
                        </ul>
                        <p className="total-gagnant">
                        Total projets concernés: <strong>{projetHeuresList.length}</strong> 
                        </p>
                    </div>
                </div>

                <div className="list-winner col-md-8">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Projets travaillés (Heures et Pourcentages)</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponsePieChart}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Projets travaillés (Heures et interlocuteurs)</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponseTimelineChart}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
  );
}

export default TimesheetGlobal;
