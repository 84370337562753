import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import QUERY from '../../lib/query';
import Aside from '../Aside/Aside';
import STORAGE from '../../lib/storage';

require("highcharts/modules/timeline")(Highcharts);

if (typeof Highcharts === 'object') {
    highcharts3d(Highcharts)
    HighchartsExporting(Highcharts)
    
}

const options = [
    { label: 'Thing 1', value: 1},
    { label: 'Thing 2', value: 2},
];

function TimesheetGlobal() {
    const [reponsePieChart, setReponsePieChart] = useState(null);
    const [reponseChart, setReponseChart] = useState(null);
    const [reponseTimelineChart, setReponseTimelineChart] = useState(null);
    const [reponseEstimeTravaile, setReponseEstimeTravaile] = useState(null);
    const [reponseEstimeTravailePackage, setReponseEstimeTravailePackage] = useState(null);
    const [reponseLine, setReponseLine] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDate2, setCurrentDate2] = useState(new Date());
    const [projets, setProjets] = useState([]);
    const [users, setUsers] = useState([]);
    const [packages, setPackages] = useState([]);
    const [allPackages, setAllPackages] = useState([]);
    const [currentPackages, setCurrentPackages] = useState([]);
    const [currentProjets, setCurrentProjets] = useState([]);
    const [projetHeuresList, setProjetHeuresList] = useState([]);
    const [packageHeuresList, setPackageHeuresList] = useState([]);
    const [nbrTimesheetSansPackage, setNbrTimesheetSansPackage] = useState(0);
    const [nbrTimesheetAvecPackage, setNbrTimesheetAvecPackage] = useState(0);

    const [personnes, setPersonnes] = useState([]);
    const [selected, setSelected] = useState("");
    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        var date = STORAGE.getDateRange();
        if (!date) {
            date = STORAGE.getDefaultRange();
        }
        const date1 = date.start;
        const date2 = date.end;
        setCurrentDate(date1);
        setCurrentDate2(date2);
        QUERY.allProjets()
        .then(data => {
            const projets = data.map(d => ({
                label: d.name,
                value: d.id
            }));
            setProjets([{
                label: "TOUT", 
                value: "*"
            },
            ...projets]);
            setCurrentProjets([{
                label: "TOUT", 
                value: "*"
            },
            ...projets])

            QUERY.allPackages()
            .then(data => {
                const packages = data.map(d => ({
                    label: d.name,
                    value: d.id,
                    projetId: d.projet.id
                }));
                setPackages([{
                    label: "TOUT", 
                    value: "*",
                    projetId: 0
                },
                ...packages]);
                setAllPackages([{
                    label: "TOUT", 
                    value: "*",
                    projetId: 0
                },
                ...packages]);
                setCurrentPackages([{
                    label: "TOUT", 
                    value: "*",
                    projetId: 0
                },
                ...packages])
                initDate(date1, date2, projets, packages)
            })
            .catch(err => {
            })
        })
        .catch(err => {
        })
    }, [])

    const handleDateChange = (date) => {
        console.log(date)
        setCurrentDate(date[0]);
        setCurrentDate2(date[1]);
        initDate(date[0], date[1], currentProjets, currentPackages);
        STORAGE.setDateRange(date[0], date[1])
    }

    const handlePackageChanges = (value, event) => {
        if (event.action === "select-option" && event.option.value === "*") {
            setCurrentPackages(packages);
            initDate(currentDate, currentDate2, currentProjets, packages)
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            setCurrentPackages([]);
            initDate(currentDate, currentDate2, currentProjets, [])
        } else if (event.action === "deselect-option") {
            setCurrentPackages(value.filter(o => o.value !== "*"));
            initDate(currentDate, currentDate2, currentProjets, value.filter(o => o.value !== "*"))
        } else if (value.length === packages.length - 1) {
            setCurrentPackages(packages);
            initDate(currentDate, currentDate2, currentProjets, packages)
        } else {
            setCurrentPackages(value);
            initDate(currentDate, currentDate2, currentProjets, value)
        }
    }

    const handleProjetChanges = (value, event) => {
        let _projets = [];
        if (event.action === "select-option" && event.option.value === "*") {
            _projets = projets;
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            _projets = [];
        } else if (event.action === "deselect-option") {
            _projets = value.filter(o => o.value !== "*");
        } else if (value.length === projets.length - 1) {
            _projets = projets;
        } else {
            _projets = value;
        }
        const _packages = [
            {
                label: "TOUT", 
                value: "*",
                projetId: 0
            },
            ...allPackages.filter(pack => {
                return _projets.find(projet => projet.value == pack.projetId)
            })
        ]
        setPackages(_packages);
        setCurrentPackages(_packages);
        setCurrentProjets(_projets);
        initDate(currentDate, currentDate2, _projets, _packages)
    }

    
    const initDate = (date, date2, projetIds, userIds) => {
        var _projetIds = '[';
        var _userIds = '[';
        projetIds.forEach(projet => {
            if (projet.value != "*") {
                _projetIds += `"${projet.value}" `;
            }
        });
        console.log("userIds : ", userIds);
        console.log("userIds : ", userIds);
        userIds.forEach(user => {
            if (user.value != "*") {
                _userIds += `"${user.value}" `;
            }
        });
        _projetIds += ']';
        _userIds += ']';
        QUERY.getUserTimesheetPackageFilter({date, date2, projetIds: _projetIds, packageIds: _userIds})
        .then((res) => {
            const projets = res.allProjets;
            console.log(res);
            let data = []; 
            let dataFull = [];
            let dataNegatif = []; 
            let dataProjet = [];
            let dataTimeline = [];
            let _timesheetSansPackage = 0;
            let packagesList = [[
                'Timesheet sans package',
                0,
                '0'
            ]];
            var userTimesheets = [];
            let categorieProjets = projets.map((projet, index) => {
                var duree = 0;
                projet && projet.timesheets && projet.timesheets.forEach(timesheet => {
                    const heureMinute = timesheet.duration && timesheet.duration != 'null' ? timesheet.duration.split(':') : ['0','0'];
                    const currentDuree = (parseInt(heureMinute[0]) + parseFloat((parseInt(heureMinute[1]) / 60).toFixed(2)));
                    duree += currentDuree;
                    
                    const usr = userTimesheets.find(user => timesheet && timesheet.utilisateur && user.id === timesheet.utilisateur.id);
                    if (usr) {
                        usr.duree += currentDuree;
                        timesheet.package && timesheet.package.id ? usr.timesheetAvecPackage += currentDuree : usr.timesheetSansPackage += currentDuree 
                        let userProject = usr.projets && usr.projets.find(pr => timesheet.projet && timesheet.projet && pr.id == timesheet.projet.id)
                        let userPackage = usr.packages && usr.packages.find(pr => timesheet.package && timesheet.package && pr.id == timesheet.package.id)
                        if(userPackage) {
                            userPackage.duree += currentDuree;
                        }
                        else {
                            if (timesheet.package && timesheet.package.id) {
                                usr.packages.push({
                                    id: (timesheet.package && timesheet.package.id) ? timesheet.package.id : '',
                                    nameProjet: ((timesheet.projet && timesheet.projet.contact && timesheet.projet.contact.name_1 ? timesheet.projet.contact.name_1 : '') + ' : ' + (timesheet && timesheet.package ? (timesheet.package.name) :  '----- -----')),
                                    duree: currentDuree,
                                    projetId: (timesheet.projet && timesheet.projet.id) ? timesheet.projet.id : ''
                                })
                            }
                            else {
                                if(userProject) {
                                    userProject.duree += currentDuree;
                                }
                                else {
                                    usr.projets.push({
                                        id: (timesheet.projet && timesheet.projet.id) ? timesheet.projet.id : '',
                                        nameProjet: (timesheet.projet && timesheet.projet.contact && timesheet.projet.contact.name_1 ? timesheet.projet.contact.name_1 : '') + ' : ' + (timesheet.projet && timesheet.projet.name) ? timesheet.projet.name : '',
                                        duree: currentDuree
                                    })
                                }
                            }
                        }
                    }
                    else {
                        let datum = {
                            name: timesheet && timesheet.utilisateur ? (timesheet.utilisateur.firstname + ' ' + timesheet.utilisateur.lastname) :  '----- -----',
                            duree: currentDuree,
                            id: timesheet && timesheet.utilisateur ? timesheet.utilisateur.id : '',
                            timesheetAvecPackage: (timesheet.package && timesheet.package.id) ? currentDuree : 0,
                            timesheetSansPackage: !(timesheet.package && timesheet.package.id) ? currentDuree : 0,
                            projets: [],
                            packages: []
                        };
                        if (timesheet.package && timesheet.package.id) {
                            datum.packages.push({
                                id: (timesheet.package && timesheet.package.id) ? timesheet.package.id : '',
                                nameProjet: ((timesheet.projet && timesheet.projet.contact && timesheet.projet.contact.name_1 ? timesheet.projet.contact.name_1 : '') + ' : ' + (timesheet && timesheet.package ? (timesheet.package.name) :  '----- -----')),
                                duree: currentDuree,
                                projetId: (timesheet.projet && timesheet.projet.id) ? timesheet.projet.id : ''
                            })
                        }
                        else {
                            datum.projets.push({
                                id: (timesheet.projet && timesheet.projet.id) ? timesheet.projet.id : '',
                                nameProjet: (timesheet.projet && timesheet.projet.contact && timesheet.projet.contact.name_1 ? timesheet.projet.contact.name_1 : '') + ' : ' + (timesheet.projet && timesheet.projet.name) ? timesheet.projet.name : '',
                                duree: currentDuree
                            })
                        }
                        console.log('currentDate : ', currentDuree, 'datum : ', datum)
                        userTimesheets.push(datum)
                    }
                    if (timesheet && timesheet.package && timesheet.package.id) {
                        const pack = packagesList.find(packag => timesheet && timesheet.package && packag[2] === timesheet.package.id);
                        if (pack) {
                            pack[1] += currentDuree;
                        }
                        else {
                            packagesList.push([
                                ((timesheet.projet && timesheet.projet.contact && timesheet.projet.contact.name_1 ? timesheet.projet.contact.name_1 : '') + ' : ' + (timesheet && timesheet.package ? (timesheet.package.name) :  '----- -----')),
                                currentDuree,
                                timesheet && timesheet.package ? timesheet.package.id : ''
                            ])
                        }
                    }
                    else {
                        _timesheetSansPackage += currentDuree;
                        const pack = packagesList.find(packag => packag[2] == '0');
                        pack[1] += currentDuree;
                    }
                });
                dataProjet.push([(projet ? projet.name : ''), duree]);
                var description = '';
                userTimesheets && userTimesheets.forEach((timeline, index) => {
                    description += ((index != 0 ? ' - ' : '') + (timeline.name + ' : ' + timeline.duree + ' heure(s)')) 
                });
                dataTimeline.push({
                    name: (projet ? projet.name : ''),
                    label: (currentDate ? (`${('0' + ((new Date(currentDate)).getDate())).slice(-2)}-${('0' + ((new Date(currentDate)).getMonth() + 1)).slice(-2)}-${(new Date(currentDate)).getFullYear()}`) : '..-..-..') + ' ; ' + duree + ' heure(s)',
                    description
                })
                return projet.name
            });

            setPackageHeuresList(packagesList);

            console.log(userTimesheets)
            if (userTimesheets && userTimesheets[0]) {
                setPersonnes(userTimesheets);
                handleUserChnage(userTimesheets[0])
            }
            else {
                setPersonnes([]);
                setSelected('');
                setSelectedUser({});
            }

            dataFull = userTimesheets.sort((x,y) => y.duree-x.duree);
            let categories = [];
            data = [];
            let dataSansPackage = [];
            let totalTimesheetAvecPackage = 0;
            let totalTimesheetSansPackage = 0;
            dataFull.forEach(d => {
                totalTimesheetAvecPackage += d.timesheetAvecPackage;
                totalTimesheetSansPackage += d.timesheetSansPackage;
                categories.push(d.name)
                data.push(d.timesheetAvecPackage);
                dataSansPackage.push(parseFloat((d.timesheetSansPackage)));
            });

            let packageValues = [];
            const packageCategories = packagesList.map((pd) => {
                packageValues.push(pd[1])
                return pd[0]
            })

            console.log(packageCategories, packageValues)

            setNbrTimesheetAvecPackage(totalTimesheetAvecPackage.toFixed(2));
            setNbrTimesheetSansPackage(totalTimesheetSansPackage.toFixed(2));

            console.log("packagesList : ", packagesList)

            setProjetHeuresList(dataProjet.sort((x,y) => y[1]-x[1]));

            setReponseChart({
                    chart: {
                        type: 'column',
                    },
                    xAxis: {
                        categories
                    },
                    title: {
                        text: null
                    },
                    plotOptions: {
                        series: {
                            depth: 25,
                            colorByPoint: false
                        }
                    },
                    series: [{
                        name: 'Avec package',
                        color: "#000",
                        data: data,
                        showInLegend: true
                    },
                    {
                        name: 'Sans package',   
                        color: "#FF0000",
                        data: dataSansPackage,
                        showInLegend: true
                    }
                ]
            });

            setReponsePieChart({
                chart: {
                    type: 'pie',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    options3d: {
                        enabled: true,
                        alpha: 45,
                        beta: 0
                    }
                },
                title: {
                    text: null
                },
                accessibility: {
                    point: {
                        valueSuffix: 'heure(s)'
                    }
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y}heure(s) -> {point.percentage:.1f}%</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        depth: 35,
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}'
                        }
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Timesheet',
                    data: packagesList
                }]
            });
        
            setReponseEstimeTravailePackage({
                chart: {
                    type: 'bar',
                    height: '65%'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: packageCategories,
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Timesheet sur les packages',
                        align: 'high'
                    },
                    labels: {
                        overflow: 'justify'
                    }
                },
                tooltip: {
                    valueSuffix: ' heures'
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'bottom',
                    x: -40,
                    y: 80,
                    floating: true,
                    borderWidth: 1,
                    backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                    shadow: true
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name: 'Timesheet sur le package',
                        color: "#000",
                        data: packageValues
                    }
                ]
            })

            setReponseTimelineChart({
                chart: {
                    type: 'timeline'
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: '<h5>{chartTitle}</h5>' +
                            '<div>{typeDescription}</div>' +
                            '<div>{chartSubtitle}</div>' +
                            '<div>{chartLongdesc}</div>' +
                            '<div>{viewTableButton}</div>'
                    },
                    point: {
                        valueDescriptionFormat: '{index}. {point.label}. {point.description}.'
                    }
                },
                xAxis: {
                    visible: false
                },
                yAxis: {
                    visible: false
                },
                title: {
                    text: null
                },
                series: [{
                    data: dataTimeline
                }]
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const handleUserChnage = (user) => {
        setSelected(user.id);
        setSelectedUser(user);
        let dataPackage = [];
        let dataProjet = [];
        let categories = user.packages.map((pack) => {
            let _user = user.projets.find(e => e.id === pack.projetId);
            if (_user) {
                dataProjet.push(_user.duree)
            }
            else {
                dataProjet.push(0)
            }
            dataPackage.push(pack.duree);
            return pack.nameProjet
        })
        let _projets = user.projets.filter(pr => {
            let d = user.packages.find(pac => pr.id == pac.projetId);
            return d ? false : true
        });
        _projets.forEach((pr) => {
            categories.push(pr.nameProjet);
            dataPackage.push(0);
            dataProjet.push(pr.duree);
        }) 
           
        
        setReponseEstimeTravaile({
            chart: {
                type: 'bar',
                height: '65%'
            },
            title: {
                text: null
            },
            xAxis: {
                categories: categories,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Timesheet sur les packages',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ' heures'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'bottom',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    name: 'Timesheet sur le package',
                    color: "#000",
                    data: dataPackage
                }, 
                {
                    name: 'Timesheet sans package du projet concerné',
                    color: "#FF0000",
                    data: dataProjet
                }
            ]
        })
    }

  return (
    <>
        <Aside/>
        <div className="content-page">
            <h1>Packages et heures travaillées</h1>

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={projets} 
                onChange={handleProjetChanges}
                value={currentProjets}
            />

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={packages} 
                onChange={handlePackageChanges}
                value={currentPackages}
            />

            <DateRangePicker className="time-picker"
                onChange={e => handleDateChange(e)}
                value={[currentDate, currentDate2]}
            />

            <div className="full-width">
                <div className="content-listess list-winner col-md-12 ">
                    {
                        personnes && personnes[0] &&
                        <div className="list content" id="list">
                            <ul style={{maxHeight: '450px'}}> 
                                {
                                    personnes && personnes.map((user) => (
                                        <li key={user.id} onClick={e => {
                                            handleUserChnage(user)
                                        }}>
                                            <a  className={selected == user.id ? "selected" : ""}> {user.name} </a>
                                        </li>
                                    ))
                                }
                            </ul> 
                            <div className="stat">
                                <div>
                                    <h4 className="userselected">{selectedUser.name}</h4>
                                    <div className="list-winner col-md-4">
                                        <h4 style={{textAlign: 'center', marginTop: '10px'}}>Timesheet</h4>
                                        <div className="contentPrice" style={{background: '#000', color: "#ffffff", marginTop: '0px', minHeight: '160px'}}>
                                            <h3 style={{textAlign: 'center'}}>Avec Package</h3>
                                            <h1 style={{textAlign: 'center', paddingBottom: '20px'}}>{selectedUser.timesheetAvecPackage ? selectedUser.timesheetAvecPackage.toFixed(2) : 0} H</h1>
                                        </div>
                                        <div className="contentPrice" style={{background: '#ff0000', color: "#ffffff", minHeight: '160px'}}>
                                            <h3 style={{textAlign: 'center'}}>Sans package</h3>
                                            <h1 style={{textAlign: 'center', paddingBottom: '20px'}}>{selectedUser.timesheetSansPackage ? selectedUser.timesheetSansPackage.toFixed(2) : 0} H</h1>
                                        </div>
                                    </div>
                                    <div className="list-winner col-md-8">
                                        <div className="">
                                            <h2 style={{textAlign: 'center'}}>Timesheets sur les packages</h2>
                                            <div className="img-diagramme">
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={reponseEstimeTravaile}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    }
                </div>
        
            </div>   

            {
                personnes && personnes[0] &&
                <div className="full-width">
                    <div className="list-winner col-md-6">
                        <div className="contentPrice" style={{background: '#000', color: "#ffffff", minHeight: '160px'}}>
                            <h3 style={{textAlign: 'center'}}>Total timesheet Avec Package</h3>
                            <h1 style={{textAlign: 'center', paddingBottom: '20px'}}>{nbrTimesheetAvecPackage} H</h1>
                        </div>
                    </div>
                    <div className="list-winner col-md-6">
                        <div className="contentPrice" style={{background: '#ff0000', color: "#ffffff", minHeight: '160px'}}>
                            <h3 style={{textAlign: 'center'}}>Total timesheet Sans package</h3>
                            <h1 style={{textAlign: 'center', paddingBottom: '20px'}}>{nbrTimesheetSansPackage} H</h1>
                        </div>
                    </div>
                </div>  
            }      

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Timesheets avec package / Timesheets sans package</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponseChart}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width">
                <div className="list-winner">
                    <div className="content">
                        <h2>Packages & Heures</h2>
                        <ul>
                        {
                            packageHeuresList && packageHeuresList.map((projet, index) => (
                            <li key={index}>
                                <div className="img-profil">
                                <div className="timesheet">{projet && projet[1] ? parseInt(projet[1]) : '00'}</div>
                                </div>
                                <div className="nom-profil">{projet && projet[0] ? (projet[0].length > 40 ? (projet[0]/*.substr(0,40) + '..'*/) : projet[0]) : ''}</div>
                            </li>
                            ))
                        }
                        </ul>
                        <p className="total-gagnant">
                        Total packages concernés: <strong>{packageHeuresList.length}</strong> 
                        </p>
                    </div>
                </div>

                <div className="list-winner col-md-8">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Packages travaillés (Heures et Pourcentages)</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponsePieChart}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content">
                        <h2 style={{textAlign: 'center'}}>Timesheets globals sur les packages</h2>
                        <div className="img-diagramme">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={reponseEstimeTravailePackage}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
  );
}

export default TimesheetGlobal;
