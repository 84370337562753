import CLIENT from './config';
import { gql } from '@apollo/client';

const client = CLIENT.client;
const clientStream = CLIENT.clientStream;

const getTotals = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        {
          _allUserBexiosMeta (
            where: {
              isHidden_not: true
            }
          ) {count}
          _allProjetsMeta (
            where: {
              isHidden_not: true
            }
          ) {count}
          _allTimesheetsMeta {count}
          _allUsersMeta {count}
        }
      `
  })
  return result.data;
}

const getTotalInactifs = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        {
          _allUserBexiosMeta (
            where: {
              isHidden: true
            }
          ) {count}
          _allProjetsMeta (
            where: {
              isHidden: true
            }
          ) {count}
        }
      `
  })
  return result.data;
}

const getUserBexios = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allUserBexios (
            where: {
              isHidden_not: true
            }
            sortBy: id_DESC
          ) {
            id
            id_bexio
            firstname
            lastname
            cout
            email
            _timesheetsMeta {
              count
            }
          }
        }
      `
  })
  return result.data.allUserBexios;
}

const getLightUserBexios = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allUserBexios (
            where: {
              isHidden_not: true
            }
            sortBy: id_DESC
          ){
            id
            firstname
            lastname
          }
        }
      `
  })
  return result.data.allUserBexios;
}

const getTimesheetByProject = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allProjets (
            where: {
              isHidden_not: true
            }
            sortBy: id_DESC
          ) {
            id
            name
            _timesheetsMeta {
              count
            }
          }
        }
      `
  })
  return result.data.allProjets;
}

const getUserTimesheet = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allUserBexios (
            where: {
              isHidden_not: true
            }
          ) {
            firstname
            lastname
            timesheets (
              where: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                projet: {
                  isHidden_not: true
                }
              }
            ) {
              id
              duration
              projet {
                id
                id_bexio
                name
              }
            }
          }
          allProjets (
            where: {
              isHidden_not: true
              timesheets_some: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                utilisateur: {
                  isHidden_not: true
                }
              }
            }
          ) {
            id
            id_bexio
            name
            timesheets (
              where: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                utilisateur: {
                  isHidden_not: true
                }
              }
            ) {
              id
              date
              duration
              utilisateur {
                id
                firstname
                lastname
              }
            }
          }
        }
      `
  })
  return result.data;
}

const getUserTimesheetFilter = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allUserBexios (
            where: {
              id_in: ${data.userIds}
              timesheets_some: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                projet: {
                  id_in:  ${data.projetIds}
                }
              }
            }
          ){
            firstname
            lastname
            timesheets (
              where: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                projet: {
                  id_in:  ${data.projetIds}
                }
                utilisateur: {
                  id_in:  ${data.userIds}
                }
              }
            ) {
              id
              duration
              projet {
                id
                id_bexio
                name
              }
            }
          }
          allProjets (
            where: {
              id_in: ${data.projetIds}
              timesheets_some: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                utilisateur: {
                  id_in:  ${data.userIds}
                }
              }
            }
          ) {
            id
            id_bexio
            name
            timesheets (
              where: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                projet: {
                  id_in:  ${data.projetIds}
                }
                utilisateur: {
                  id_in:  ${data.userIds}
                }
              }
            ) {
              id
              date
              duration
              utilisateur {
                id
                firstname
                lastname
              }
            }
          }
        }
      `
  })
  return result.data;
}

const getUserTimesheetPackageFilter = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allProjets (
            where: {
              id_in: ${data.projetIds}
              timesheets_some: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                OR: [
                  {
                    package: {
                      id_in:  ${data.packageIds}
                    }
                  },
                  {
                    package_is_null: true
                  }
                ]
              }
            }
          ) {
            id
            id_bexio
            name
            timesheets (
              where: {
                date_gte: "${new Date(data.date).toISOString()}"
                date_lte: "${new Date(data.date2).toISOString()}"
                projet: {
                  id_in:  ${data.projetIds}
                }
                OR: [
                  {
                    package: {
                      id_in:  ${data.packageIds}
                    }
                  },
                  {
                    package_is_null: true
                  }
                ]
              }
            ) {
              id
              date
              duration
              package {
                id
                id_bexio
                name
              }
              projet {
                id
                id_bexio
                name
                contact {
                  name_1
                }
              }
              utilisateur {
                id
                firstname
                lastname
              }
            }
          }
        }
      `
  })
  return result.data;
}

const getUserTimesheetProjetFilter = async (data) => {
  await clientStream.cache.reset();
  const result = await clientStream
  .query({
      query: gql`
        query {
          allUserBexios (
            where: {
              timesheets_some: {
                projet: {
                  id_in:  ${data.projetIds}
                }
                OR: [
                  {
                    package: {
                      id_in:  ${data.packageIds}
                    }
                  },
                  {
                    package_is_null: true
                  }
                ]
              }
            }
          ){
            firstname
            lastname
            cout
            isHidden
            exclureFraisIndirect
            timesheets (
              where: {
                projet: {
                  id_in:  ${data.projetIds}
                }
                OR: [
                  {
                    package: {
                      id_in:  ${data.packageIds}
                    }
                  },
                  {
                    package_is_null: true
                  }
                ]
              }
            ) {
              id
              duration
              date
              projet {
                id
                id_bexio
                name
                pr_budget_type_amount
              }
              contact {
                id
                name_1
              }
            }
          }
          allProjets (
            where: {
              id_in: ${data.projetIds}
              timesheets_some: {
                OR: [
                  {
                    package: {
                      id_in:  ${data.packageIds}
                    }
                  },
                  {
                    package_is_null: true
                  }
                ]
              }
            }
          ) {
            id
            id_bexio
            name
            pr_budget_type_amount
            timesheets (
              where: {
                projet: {
                  id_in:  ${data.projetIds}
                }
                OR: [
                  {
                    package: {
                      id_in:  ${data.packageIds}
                    }
                  },
                  {
                    package_is_null: true
                  }
                ]
              }
            ) {
              id
              date
              duration
              utilisateur {
                id
                cout
                firstname
                lastname
              }
              contact {
                id
                name_1
              }
            }
          }
        }
      `
  })
  return result.data;
}

const allFilteredUserBexios = async (data) => {
  await client.cache.reset();
  var _query = '';
  if (data.search && data.search != '') {
    _query = `query {
      allUserBexios(
        first: ${data.size}
        skip: ${(data.page - 1) * data.size}
        sortBy: id_DESC
        where: {
          OR: [
            {
              firstname_contains_i: "${data.search}"
            }
            {
              lastname_contains_i: "${data.search}"
            }
            {
              email_contains_i: "${data.search}"
            }
            {
              cout: ${parseInt(data.search) ? parseInt(data.search) : 0}  
            }
          ]
        }
      ) {
        id
        firstname
        lastname
        email
        salutation_type
        cout
        _timesheetsMeta {
          count
        }
      }
      _allUserBexiosMeta (
        where: {
          OR: [
            {
              firstname_contains_i: "${data.search}"
            }
            {
              lastname_contains_i: "${data.search}"
            }
            {
              email_contains_i: "${data.search}"
            }
            {
              cout: ${parseInt(data.search) ? parseInt(data.search) : 0}  
            }
          ]
        }
      ) {
        count
      }
    }`
  }
  else {
    _query = `query {
      allUserBexios(
        first: ${data.size}
        skip: ${(data.page - 1) * data.size}
        sortBy: id_DESC
        where: {

        }
      ) {
        id
        firstname
        lastname
        email
        salutation_type
        cout
        _timesheetsMeta {
          count
        }
      }
      _allUserBexiosMeta {
        count
      }
    }`
  }
  const result = await client
  .query({
      query: gql`
        ${_query}
      `
  })
  return result.data;
}

const getTimesheets = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          getTimeSheets(limit:${data.limit})
        }
      `
  })
  return result.data;
}

const getProjetTypes = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          getProjetTypes(limit:${data.limit})
        }
      `
  })
  return result.data;
}

const getProjets = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          getProjets(limit:${data.limit})
        }
      `
  })
  return result.data;
}

const getUsers = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          getUsers(limit:${data.limit})
        }
      `
  })
  return result.data;
}

const getUserBexioById = async (data) => {
    await client.cache.reset();
    const result = await client
    .query({
        query: gql`
          query {
            UserBexio(
              where: {
                id: "${data.id}"
              }
            ) {
              id
              id_bexio
              firstname
              isHidden
              exclureFraisIndirect
              lastname
              email
              cout
              salutation_type
              is_superadmin
              is_accountant
              timesheets {
                id
                id_bexio
                projet {
                  id
                  id_bexio
                  name
                  start_date
                }
              }
            }
          }
        `
    })
    return result.data.UserBexio;
}



const getTimesheetById = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          Timesheet(
            where: {
              id: "${data.id}"
            }
          ) {
            id
            id_bexio
            text
            status_id
            client_service_id
            allowable_bill
            charge
            contact_id
            sub_contact_id
            pr_package_id
            pr_milestone_id
            travel_charge
            travel_time
            travel_charge
            travel_distance
            estimated_time
            running
            trackingType
            trackingDate
            trackingDuration
            trackingStart
            trackingEnd
            projet {
              id
              name
            }
            utilisateur {
              id
              firstname
              lastname
            }
            date
            duration
          }
        }
      `
  })
  return result.data.Timesheet;
}

const getTypeProjetById = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          TypeDeProjet(
            where: {
              id: "${data.id}"
            }
          ) {
            id
            id_bexio
            name
            projets {
              id
              id_bexio
              name
            }
          }
        }
      `
  })
  return result.data.TypeDeProjet;
}

const allFilteredTimesheets= async (data) => {
  await client.cache.reset();
  var _query = '';
  if (data.search && data.search != '') {
    _query = `query {
      allTimesheets(
        first: ${data.size}
        skip: ${(data.page - 1) * data.size}
        sortBy: date_DESC
        where: {
          OR: [
            {
              projet: {
                name_contains_i: "${data.search}"
              }
            }
            {
              utilisateur: {
                OR: [
                  {
                    firstname_contains_i: "${data.search}"
                  }
                  {
                    lastname_contains_i: "${data.search}"
                  }
                ]
              }
            }
            {
              date: "${data.search}"
            }
            {
              duration_contains_i: "${data.search}"
            }
          ]
        }
      ) {
        id
        projet {
          id
          name
        }
        utilisateur {
          id
          firstname
          lastname
        }
        date
        duration
      }
      _allTimesheetsMeta (
        where: {
          OR: [
            {
              projet: {
                name_contains_i: "${data.search}"
              }
            }
            {
              utilisateur: {
                OR: [
                  {
                    firstname_contains_i: "${data.search}"
                  }
                  {
                    lastname_contains_i: "${data.search}"
                  }
                ]
              }
            }
            {
              date: "${data.search}"
            }
            {
              duration_contains_i: "${data.search}"
            }
          ]
        }
      ) {
        count
      }
    }`
  }
  else {
    _query = `query {
      allTimesheets(
        first: ${data.size}
        skip: ${(data.page - 1) * data.size}
        sortBy: date_DESC
      ) {
        id
        projet {
          id
          name
        }
        utilisateur {
          id
          firstname
          lastname
        }
        date
        duration
      }
      _allTimesheetsMeta {
        count
      }
    }`
  }
  const result = await client
  .query({
      query: gql`
        ${_query}
      `
  })
  return result.data;
}

const allFilteredTypeProjets = async (data) => {
  await client.cache.reset();
  var _query = '';
  if (data.search && data.search != '') {
    _query = `query {
      allTypeDeProjets(
        first: ${data.size}
        skip: ${(data.page - 1) * data.size}
        sortBy: id_DESC
        where: {
          OR: [
            {
              name_contains_i: "${data.search}"
            }
            {
              id_bexio: ${parseInt(data.search) ? parseInt(data.search) : 0}  
            }
          ]
        }
      ) {
        id
        id_bexio
        name
        _projetsMeta {
          count
        }
        projets {
          id
          id_bexio
          name
        }
      }
      _allTypeDeProjetsMeta (
        where: {
          OR: [
            {
              name_contains_i: "${data.search}"
            }
            {
              id_bexio: ${parseInt(data.search) ? parseInt(data.search) : 0}  
            }
          ]
        }
      ) {
        count
      }
    }`
  }
  else {
    _query = `
      query {
        allTypeDeProjets(
          first: ${data.size}
          skip: ${(data.page - 1) * data.size}
          sortBy: id_DESC
        ) {
          id
          id_bexio
          name
          _projetsMeta {
            count
          }
          projets {
            id
            id_bexio
            name
          }
        }
        _allTypeDeProjetsMeta {
          count
        }
      }
    `
  }
  const result = await client
  .query({
      query: gql`
        ${_query}
      `
  })
  return result.data;
}

const allFilteredProjets = async (data) => {
  await client.cache.reset();
  var _query = '';
  if (data.search && data.search != '') {
    _query = `query {
      allProjets(
        first: ${data.size}
        skip: ${(data.page - 1) * data.size}
        sortBy: id_DESC
        where: {
          OR: [
            {
              name_contains_i: "${data.search}"
            }
            {
              id_bexio: ${parseInt(data.search) ? parseInt(data.search) : 0}  
            }
          ]
        }
      ) {
        id
        id_bexio
        name
        start_date
        pr_budget_type_amount
        typeProject {
          id
          name
        }
        contact {
          id
          name_1
        }
      }
      _allProjetsMeta (
        where: {
          OR: [
            {
              name_contains_i: "${data.search}"
            }
            {
              id_bexio: ${parseInt(data.search) ? parseInt(data.search) : 0}  
            }
          ]
        }
      ) {
        count
      }
    }`
  }
  else {
    _query = `
      query {
        allProjets(
          first: ${data.size}
          skip: ${(data.page - 1) * data.size}
          sortBy: id_DESC
        ) {
          id
          id_bexio
          start_date
          name
          pr_budget_type_amount
          typeProject {
            id
            name
          }
          contact {
            id
            name_1
          }
        }
        _allProjetsMeta {
          count
        }
      }
    `
  }
  const result = await client
  .query({
      query: gql`
        ${_query}
      `
  })
  return result.data;
}

const allProjets = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allProjets (
            where: {
              isHidden_not: true
            }
            sortBy: id_DESC
          ) {
            id
            id_bexio
            start_date
            name
            pr_budget_type_amount
            contact {
              id
              name_1
            }
          }
        }
      `
  })
  return result.data.allProjets;
}

const allUserBexios = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allUserBexios (
            where: {
              isHidden_not: true
            }
            sortBy: id_DESC
          ) {
            id
            firstname
            lastname
          }
        }
      `
  })
  return result.data.allUserBexios;
}

const allContacts = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allContacts {
            id
            name_1
          }
        }
      `
  })
  return result.data.allContacts;
}

const allContactsWithProject = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
      query {
        allContacts (
          where: {
            projets_some: {
              id_not: null
            }
          }
        ) {
          id
          name_1
        }
      }
      `
  })
  return result.data.allContacts;
}

const allPackages = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allPackages {
            id
            name
            projet {
              id
            }
          }
        }
      `
  })
  return result.data.allPackages;
}

const allParametrages = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allParametrages {
            id
            code
            nom
            unite
            description
            valueType
            valeur
          }
          _allParametragesMeta {count}
        }
      `
  })
  return result.data;
}

const getBexioUserById = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          UserBexio (
            where: {
              id: "${data.id}"
            }
          ) {
            id
            id_bexio
            isHidden
            exclureFraisIndirect
            firstname
            lastname
            cout
            email
          }
        }
      `
  })
  return result.data.UserBexio;
}

const getProjetById = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          Projet (
            where: {
              id: "${data.id}"
            }
          ) {
            id
            id_bexio
            name
            start_date
            pr_budget_type_amount
            typeProject {
              id
              name
            }
            contact {
              id
              name_1
            }
          }
        }
      `
  })
  return result.data.Projet;
}

const getFraisIndirect = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
      query {
        allParametrages (
          where: {
            code_in: ["FI", "PLAGE"]
          }
        ) {
          id
          code
          nom
          valeur
          valueType
        }
      }
      `
  })
  return result.data.allParametrages;
}

const GetEmailAdministration = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
      query {
          allParametrages(
            where: {
              code: "EMAIL_ADMINISTRATEUR"
            }
          ) {
            id
            valeur
          }
      }
      `
  })
  return result.data.allParametrages;
}

const getFirstTimesheetDate = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
      {
        allTimesheets (
          first: 1
          orderBy: "date_desc"
        )
        {
          id
          date
        }
      }
      `
  })
  return result.data.allTimesheets;
}

const sendEmail = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
      {
        getweekly(
          email: "${data.email}",
          debut: "${data.debut}",
          fin: "${data.fin}",
          cc_email: "${data.cc_email}",
          objet_email: "${data.objet_email}",
          content_email: "${data.content_email}"
        )
      }
      `
  })
  return result.data;
}

const sendGlobal = async (data) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
      {
        getglobal(
          email: "${data.email}",
          debut: "${data.debut}",
          fin: "${data.fin}",
          cc_email: "${data.cc_email}",
          objet_email: "${data.objet_email}",
          content_email: "${data.content_email}"
          projetIds: [
            ${data.projetIds}
          ]
        )
      }
      `
  })
  return result.data;
}

const getAllChargements = async () => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
        query {
          allOperationFinancieres (
            sortBy: id_DESC
          ) {
            id
            date
            operationTotal
            operationChargees
            operationEchecs
            rapportChargement
            fichier {
              id
              filename
            }
          }
        }
      `
  })
  return result.data;
}

const getOperationOfChargement = async (idOpFinaciere) => {
  await client.cache.reset();
  const result = await client
  .query({
      query: gql`
      {
        allOperations(
          where: { 
            operationFinanciere: { 
              id: "${idOpFinaciere}" 
            } 
          }
        ) {
          id
          date
          reference_nr
          entreeOperations {
            id
            description
            amount
            devise {
              id
              name
            }
            taxe {
              id
              name
              value
            }
            accountDebit {
              id
              name
            }
            accountCredit {
              id
              name
            }
          }
        }
      }      
      `
  })
  return result.data?.allOperations;
}

export default {
  getTotals,
  getTotalInactifs,
  getUserBexios,
  getTimesheetByProject,
  getTimesheets,
  getProjetTypes,
  getUsers,
  getUserBexioById,
  allFilteredTimesheets,
  allFilteredTypeProjets,
  getTypeProjetById,
  getProjets,
  allFilteredProjets,
  getLightUserBexios,
  getTimesheetById,
  getUserTimesheet,
  allProjets,
  allUserBexios,
  getUserTimesheetFilter,
  getUserTimesheetProjetFilter,
  getUserTimesheetPackageFilter,
  allContacts,
  allPackages,
  allFilteredUserBexios,
  allParametrages,
  getBexioUserById,
  getProjetById,
  getFraisIndirect,
  GetEmailAdministration,
  sendEmail,
  getFirstTimesheetDate,
  sendGlobal,
  allContactsWithProject,
  getAllChargements,
  getOperationOfChargement
}