import React, { useState } from 'react'
import QUERY from '../../lib/query';
import Details from '../Details'

function Form({update, openCreate, setOpenCreate}) {
  
  const [inputs, setInputs] = useState([
    {
      key: 'Id Bexio',
      value: update.id_bexio
    },
    {
      key: 'Utilisateur',
      value: update?.utilisateur?.firstname + ' ' + update?.utilisateur?.lastname
    },
    {
      key: 'Projet',
      value: update?.projet?.name
    }
  ]);
  
  const [lists, setLists] = useState([]);

  return (
    <Details titre="Détail du timesheet" lists={lists} inputs={inputs} setOpenCreate={setOpenCreate} openCreate={openCreate}/>
  );
}

export default Form;
