import React, { useState } from 'react'
import QUERY from '../../lib/query';
import Details from '../Details'

function Form({update, openCreate, setOpenCreate}) {
  
  const [inputs, setInputs] = useState([
    {
      key: 'Id Bexio',
      value: update.id_bexio
    },
    {
      key: 'Genre',
      value: update.salutation_type === 'male' ? 'Homme' : 'Femme'
    },
    {
      key: 'Prénom',
      value: update.firstname
    },
    {
      key: 'Nom',
      value: update.lastname
    },
    {
      key: 'Email',
      value: update.email
    },
    {
      key: 'cout',
      value: update.cout ? update.cout : 0 + ' CHF'
    },
    {
      key: 'Super admin ?',
      value: update.is_superadmin ? 'OUI' : 'NON'
    },
    {
      key: 'Comptable ?',
      value: update.is_accountant ? 'OUI' : 'NON'
    },
    {
      key: 'Nombre total de timesheets',
      value: update.timesheets ? (update.timesheets.length + ' fois') : '0 fois'
    },
    {
      key: "Nombre total d'heures de timesheets",
      value: update.timesheets ? (update.timesheets.length + ' heure(s)') : '0 heure'
    }
  ]);
  
  const [lists, setLists] = useState([]);
  const [invites, setInvites] = useState([]);
  const [bons, setBons] = useState(
    update.bonGagnes
  );

  // QUERY.getInvitedGamerByParrain({codeParrain: update.codeUser})
  // .then((invites) => {
  //   setInvites(invites)
  // })

  return (
    <Details titre="Détail du joueur" lists={lists} inputs={inputs} setOpenCreate={setOpenCreate} openCreate={openCreate} bons={bons} invites={invites}/>
  );
}

export default Form;
