import React, { useEffect, useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { eachDayOfInterval, isWithinInterval } from 'date-fns';
import API from '../../lib/api';
import QUERY from '../../lib/query';
import DELETE from '../../lib/delete';
import Table from '../Table';
import profil from '../../assets/images/avatar.png';
import leftArrow from '../../assets/images/left-arrow.svg';
import rightArrow from '../../assets/images/right-arrow.svg';
import SOCKET from '../../lib/socket';
import { useForm } from "react-hook-form";
import MUTATION from '../../lib/mutation';
import ALERT from '../../lib/alert';

function Listes({setOpenCreate, setOpenEdit, openCreate, refresh, setRefresh, setUpdate, setNewInput}) {
  
  const [list, setList] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [size, setSize] = useState(100);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [action, setAction] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentType, setCurrentType] = useState('');
  const [printValue, setPrintValue] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentDate2, setCurrentDate2] = useState(new Date());
  const [newFI, setNewFI] = useState(false);
  const [allPlages, setAllPlages] = useState([]);

  const [selectedPuce, setSelectedPuce] = useState({});

  const [selected, setSelected] = useState('');

  const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm();

  const headers= [
    "Nom",
    "Description",
    "valeur",
    "Actions"
  ]

  const setFilter = (filter = '') => {
      setPage(1);
      setSearch(filter);
      setList(list.filter(row => (row.find(e => e.type === 'TEXT' && e.value && e.value.toLowerCase() && e.value.toLowerCase().includes(filter.toLowerCase())))));
  }

  const handleDateChange = (date) => {
      var dates = eachDayOfInterval(
        { start: date[0], end: date[1] }
      );
      var conflit = false;
      var indexPlage = 0;
      let plageConflit = '';
      if (allPlages && allPlages[0]) {
        while (!conflit && indexPlage < allPlages.length) {
          if (newFI || allPlages[indexPlage].id !== selectedPuce.id) {
            var indexDate = 0;
            while (!conflit && indexDate < dates.length) {
              let _dates = allPlages[indexPlage].nom.split(" ")[2].split('-');
              let date1 = `${_dates[0].split('/')[1]}-${_dates[0].split('/')[0].substring(1)}-2000`;
              let date2 = `${_dates[1].split('/')[1].substring(0, _dates[1].split('/')[1].length -1)}-${_dates[1].split('/')[0]}-2000`;
              dates[indexDate].setFullYear(2000);
              if (
                isWithinInterval(
                  dates[indexDate],
                  { start: new Date(date1), end: new Date(date2) }
                )) {
                  conflit = true;
                  plageConflit =  allPlages[indexPlage].nom;
                }
                indexDate++;
            };
          }
          indexPlage++;
        }
      }
      if (!conflit) {
        setCurrentDate(date[0]);
        setCurrentDate2(date[1]);
        setSelectedPuce({
          ...selectedPuce,
          nom: `Frais indirect (${date[0].getDate()}/${date[0].getMonth() + 1}-${date[1].getDate()}/${date[1].getMonth() + 1})`
        })
      }
      else {
        ALERT.alert('error', 'Conflit entre plage de date', `La plage de date choisie est en conflit avec la plage de "${plageConflit}"`);
      }
  }

  var onSubmit = data => {
    console.log(data);
    if (newFI) {
      MUTATION.CreateParametrage({
        code: selectedPuce.code,
        unite: selectedPuce.unite,
        valueType: selectedPuce.valueType,
        nom: selectedPuce.nom,
        valeur: data.valeur,
        description: data.description
      })
      .then((val) => {
        console.log(val);
        initData();
        ALERT.alert('success', selectedPuce.nom, "Le parametre a bien été mise a jour");
        setNewFI(false);
      })
      .catch(err => {
        console.log(err);
        ALERT.alert('error', selectedPuce.nom, "Le parametre n'a pas été mise a jour");
      })
    }
    else {
      MUTATION.UpdateParametrage({
        id: data.id,
        nom: selectedPuce.nom,
        valeur: data.valeur,
        description: data.description
      })
      .then((val) => {
        console.log(val);
        initData();
        ALERT.alert('success', selectedPuce.nom, "Le parametre a bien été mise a jour");
      })
      .catch(err => {
        console.log(err);
        ALERT.alert('error', selectedPuce.nom, "Le parametre n'a pas été mise a jour");
      })
    }
  };

  const handleInitialDates = (nom) => {
    let _dates = nom.split(" ")[2].split('-');
    let date1 = `${_dates[0].split('/')[1]}-${_dates[0].split('/')[0].substring(1)}-2000`;
    let date2 = `${_dates[1].split('/')[1].substring(0, _dates[1].split('/')[1].length -1)}-${_dates[1].split('/')[0]}-2000`;
    setCurrentDate(date1);
    setCurrentDate2(date2);
  }

  const initData = () => {
    QUERY.allParametrages({page, size, search})
    .then((data) => {
      console.log('allParametrages : ', data.allParametrages)
      setTotalRow(data && data._allParametragesMeta && data._allParametragesMeta.count ? data._allParametragesMeta.count : 0)
      data && data.allParametrages && setList(data.allParametrages);
      if (data && data.allParametrages && data.allParametrages[0]) {
        // if (selected == '') {
          setSelected(data.allParametrages[0].id);
          setSelectedPuce(data.allParametrages[0]);
          setCurrentType(data.allParametrages[0].valueType);
          setValue("id", data.allParametrages[0].id);
          setValue("valeur", data.allParametrages[0].valeur);
          setValue("description", data.allParametrages[0].description);
          handleInitialDates(data.allParametrages[0].nom);
        // }
        // else {
        //   setSelectedPuce(data.allParametrages.find(e => e.id == selected));
        //   setCurrentType(data.allParametrages.find(e => e.id == selected).valueType);
        // }

        setAllPlages(data.allParametrages.filter(e => e.code === 'PLAGE'));

      }
      setTotalPage(parseInt(totalRow%size == 0 ? (totalRow / size) : ((totalRow / size) + 1)));
    })
    .catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    console.log('page : ', page, ' size : ', size)
    initData();
  }, [page, size, search]);

  useEffect(() => {
    if(refresh) {
      initData();
      setRefresh(false);
    }
  }, [refresh])

  useEffect(() => {
    switch (action.type) {
      case 'remove':
        DELETE.DELETE('le joueur', 'User', action.value)
        .then(res => { initData(); }).catch(err => { initData(); });
        setAction({});
        break;

      case 'edit':
        console.log("edit", action);
        QUERY.getJoueurById({idJoueur: action.value})
        .then((data) => {
          setUpdate(data);
          setOpenEdit(true);
        })
        .catch((err) => {
          console.log(err);
        })
        setAction({});
        break;

      case 'details':
        console.log("details", action);
        QUERY.getUserBexioById({id: action.value})
        .then((data) => {
          setUpdate(data);
          setOpenCreate(true);
        })
        .catch((err) => {
          console.log(err);
        })
        setAction({});
        break;

      default:
        break;
    }
  }, [action]);

  return (
    // <Table puces={true} pagination={true} page={page} size={size} setPage={setPage} search={search} setSearch={setSearch} setSize={setSize} totalPage={parseInt(totalRow%size == 0 ? (totalRow / size) : ((totalRow / size) + 1))} noAdd={true} csv={false} sync={false} csvData={csvData} title="Listes des parametrages" headers={headers} list={list} setOpenCreate={setOpenCreate} setOpenEdit={setOpenEdit} setNewInput={setNewInput} setAction={setAction}/>
    <div className="content-page">
        <h1>Listes des parametrages</h1>
        
        <div className="content-listes">
          <div className="list" id="list" style={{'display': 'flex'}}>
              <br />
              <ul> 
                  {
                      list && list.map((puce) => (
                          <li key={puce.id} onClick={e => {
                            if(!newFI) {
                              setSelected(puce.id);
                              setSelectedPuce(puce);
                              setValue("id", puce.id);
                              setValue("valeur", puce.valeur);
                              setValue("description", puce.description);
                              setCurrentType(puce.valueType);
                              handleInitialDates(puce.nom);
                              setPrintValue(false);
                              setTimeout(() => {
                                setPrintValue(true);
                              })
                            }
                            else {
                              ALERT.alert('warning', selectedPuce.nom, "Le parametre courant n'est pas encore enregistrer");
                            }
                          }}>
                              <a  className={selected == puce.id ? "selected" : ""}> {puce.nom} </a>
                          </li>
                      ))
                  }
                  <li>
                    <div style={{'display': 'block'}}>
                      {
                        !newFI
                        ?
                        <input onClick={e => {
                          let _date = new Date();
                          _date.setFullYear(2000);
                          let puce = {
                            id: 'newPlage',
                            code: 'PLAGE',
                            nom: `Frais indirect (${_date.getDate()}/${_date.getMonth()}-${_date.getDate()}/${_date.getMonth()})`,
                            unite: 'CHF',
                            description: '',
                            valueType: 'DECIMAL',
                            valuer: 0
                          };
                          setList([
                            ...list,
                            puce
                          ])
                          setSelected(puce.id);
                          setSelectedPuce(puce);
                          setValue("id", puce.id);
                          setValue("valeur", puce.valeur);
                          setValue("description", puce.description);
                          setCurrentType(puce.valueType);
                          setPrintValue(false);
                          setTimeout(() => {
                            setPrintValue(true);
                          })
                          setNewFI(true);
                        }} type="button" name="ajouter" id="ajouter" value="Nouveau Frais Indiect" className="ajouter new-FI" style={{'background': '#000000'}} />
                        :
                        <input onClick={e => {
                          var _list = list;
                          _list.pop()
                          setList(_list)
                          if (_list.length > 0) {
                            setSelected(_list[0].id);
                            setSelectedPuce(_list[0]);
                            setCurrentType(_list[0].valueType);
                            setValue("id", _list[0].id);
                            setValue("valeur", _list[0].valeur);
                            setValue("description", _list[0].description);
                          }
                          setNewFI(false);
                        }}  type="button" name="ajouter" id="ajouter" value="Annuler" className="ajouter new-FI" style={{'background': '#000000'}} />
                      }
                    </div>
                  </li>
              </ul> 
              <div className="stat">
                {
                  selectedPuce && selectedPuce.id &&
                  <div className="full-width">
                      <div className="col-md-12">
                        <h4 className="userselected">{selectedPuce.nom}</h4>
                        <br />
                        <h5>{selectedPuce.description}</h5>
                        <br />
                        <h5 >Valeur actuelle : {selectedPuce.valeur} {selectedPuce.unite}</h5>
                        <div className="form-add" style={{'width': '100%', 'height': 'auto', 'right': '0%', 'position': 'relative'}}>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            {/* <div className="form-select">
                                <label style={{'padding': '0px 0'}}>Type</label>
                                <select name="type" id="type" defaultValue={selectedPuce.valueType} ref={register({ required: true })} onChange={e => {
                                  setCurrentType(e.target.value);
                                }}>
                                  <option value="TEXT">TEXTE</option>
                                  <option value="DECIMAL">DECIMAL</option>
                                </select>
                                {errors["type"] && <span className="errorText">Le champs type est obligatoire</span>}
                            </div> */}
                            {
                              selectedPuce.code === 'PLAGE' &&
                              <div className="form-plage">
                                  <label style={{'padding': '0px 0'}}>Plage</label>
                                  <DateRangePicker className="time-picker-plage"
                                    format="dd-MM"
                                    onChange={e => handleDateChange(e)}
                                    value={[currentDate, currentDate2]}
                                  />
                              </div>
                            }
                            <div className="form-text">
                                <label style={{'padding': '0px 0'}}>Valeur</label>
                                {
                                  currentType == "TEXT" ?
                                  printValue && <input type="text" name='valeur' defaultValue={selectedPuce.valeur} ref={register({ required: true })} />
                                  :
                                  printValue && <input type="number" name='valeur' step="0.01" defaultValue={selectedPuce.valeur} ref={register({ required: true })} />
                                }
                                {errors['valeur'] && <span className="errorText">Le champs valeur est obligatoire</span>}
                            </div>
                            <div style={{'display': 'none'}} className="form-text">
                                <input type={"text"} name='id' defaultValue={selectedPuce.id} ref={register({ required: true })} />
                            </div>
                            <div className="form-text">
                                <label style={{'padding': '0px 0'}}>Description</label>
                                <textarea name="description" defaultValue={selectedPuce.description} ref={register({ required: true })}></textarea>
                                {errors["description"] && <span className="errorText">Le champs description est obligatoire</span>}
                            </div>

                            {
                              selectedPuce.code === 'PLAGE' && !newFI && 
                              <div className="form-submit" style={{'float': 'left'}}>
                                <input onClick={e => {
                                  MUTATION.DeleteParametrage({
                                    id: selectedPuce.id
                                  })
                                  .then((val) => {
                                    console.log(val);
                                    initData();
                                    ALERT.alert('success', selectedPuce.nom, "Le parametre a bien été supprilé");
                                    setNewFI(false);
                                  })
                                  .catch(err => {
                                    console.log(err);
                                    ALERT.alert('error', selectedPuce.nom, "Le parametre n'a pas été supprimé");
                                  })
                                }} type="button" name="ajouter" id="ajouter" value="Supprimer" className="ajouter" style={{background: '#dc3545'}} />
                              </div>
                            }

                            <div className="form-submit" style={{'float': 'right'}}>
                              <input type="submit" name="ajouter" id="ajouter" value="Enregister" className="ajouter" />
                            </div>

                          </form>
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <h4 >Valeur: {selectedPuce.valeur}</h4>
                      </div> */}
                  </div>
                }
              </div>
          </div> 
      </div>
    </div>
  );
}

export default Listes;