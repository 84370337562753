import React, { useEffect, useState } from 'react';
import MUTATION from '../../lib/mutation';
import STORAGE from '../../lib/storage';
import logo from '../../assets/images/logo.png';
import { useHistory } from "react-router-dom";
import ALERT from '../../lib/alert';

function Login() {
  let history = useHistory();
  const [user, setUser] = useState({});

  const login = () => {
    MUTATION.LOGIN(user)
    .then((data) => {
      STORAGE.setToken(data.token);
      STORAGE.setUser(data.item);
      history.push("/Dashboard");
    })
    .catch(err => {
      ALERT.alert('error', 'Echec authentification !', 'Email ou mot de passe incorrect !');
    })
  }

  useEffect(() => {
    if (STORAGE.isLogged()) history.push("/Dashboard");
  }, []);

  return (
    <div className="main-login">
    <div className="form-login">
      <header className="logo-header">
        <img src={logo} className="logo" alt="logo" />
      </header>
      <form name="" id="">
        <div className="form-text">
          <label>Email</label>
          <input onChange={e => setUser({...user, email: e.target.value})} type="email" name="email" id="email" />
        </div>
        <div className="form-text">
          <label>Password</label>
          <input onChange={e => setUser({...user, password: e.target.value})} type="password" name="password" id="password" />
        </div>
        <div className="form-submit">
          <input onClick={e => login()} type="button" name="sign" id="sign" value="Se Connecter" />
        </div>
      </form>
    </div>
    </div>
  );
}

export default Login;
