import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
// import { logout } from './logout';
import API from './api';
import STORAGE from './storage';
import ALERT from './alert';

// import fetch from "node-fetch"
import fetch from 'cross-fetch'
// import 'isomorphic-fetch'
import {createFormDataLink} from "apollo-link-form-data"
const linkStream = createFormDataLink({
  uri: API.BASE_URL+ API.PATH_API,
  fetch: fetch
})

const httpLink = createUploadLink({
  // uri: API.PATH_API,
  uri: API.BASE_URL+ API.PATH_API,
});

const authLink = setContext( async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await STORAGE.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const logoutLink = onError((error) => {
  if (error && error.response && error.response.errors && error.response.errors[0] && error.response.errors[0].name === 'AccessDeniedError') {
    ALERT.alert('error', 'Expiration !', 'Votre session est expirée. Veuillez vous reconnecter !');
    setTimeout(() => {
      STORAGE.loggout();
      // window.location.reload();
    }, 1000);
  }
})

const client = new ApolloClient({
  link: authLink.concat(logoutLink).concat(httpLink),
  cache: new InMemoryCache()
});

const clientStream = new ApolloClient({
  link: authLink.concat(logoutLink).concat(linkStream),
  cache: new InMemoryCache()
});

export default {
  client,
  clientStream
}
