import React , { Fragment } from 'react'
import Aside from './Aside/Aside'
import Accueil from './Accueil/Accueil'

function Dashboard() {
  return (
    <Fragment>
      <Aside/>
      <Accueil/>
    </Fragment>
  );
}

export default Dashboard;
