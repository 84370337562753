import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo-black.png';
import { useHistory } from "react-router-dom";
import STORAGE from '../../lib/storage';
import ALERT from '../../lib/alert';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Aside() {
  const [user, setuser] = useState(STORAGE.getUser() ? STORAGE.getUser() : {});
  const [currentActif, setCurrentActif] = useState('');
  let history = useHistory();
  useEffect(() => {
    if (!STORAGE.isLogged()) history.push("/Login");
  }, []);
  return (
    <div className="main-aside">
      <header className="logo-header">
        <img width="200" src={logo} className="logo" alt="logo" />
      </header>
      <div className="profil">
        <div className="icon-profil"></div>
        <div className="text-profil">
          <p>{user.prenom + ' ' + user.nom}</p>
          <p>{user.email}</p>
        </div>
      </div>

      <div className="menu-aside">
        <ul>
          <li><Link to="/Dashboard">Tableau de bord</Link></li>
          <li><Link to="/UserBexio">Utilisateurs Bexio</Link></li>
          {/* <li><Link to="/TypeProjet">Type de projet</Link></li> */}
          <li><Link to="/Projet">Projet</Link></li>
          <li className={currentActif == '/Timesheet' ? "main-menu actif" : 'main-menu'} onClick={e => {
              setCurrentActif((currentActif == '/Timesheet') ? '' : '/Timesheet');
            }}>
            <Link to="#">Timesheet</Link>
            <ul className={currentActif == '/Timesheet' ? "submenu actif" : 'submenu'}>
              <li><Link to="/Timesheet">Liste & synchronisation</Link></li>
              <li><Link to="/Timesheet/global">Timesheets global</Link></li>
              <li><Link to="/Timesheet/package">Timesheets package</Link></li>
              {/* <li><Link to="/Timesheet/personnes">Timesheets par personne</Link></li> */}
            </ul>
          </li>
          <li className={currentActif == '/Finance' ? "main-menu actif" : 'main-menu'} onClick={e => {
              setCurrentActif((currentActif == '/Finance') ? '' : '/Finance');
            }}> 
            <Link to="#">Finance</Link>
            <ul className={currentActif == '/Finance' ? "submenu actif" : 'submenu'}>
              <li><Link to="/Finance/operations">Opération financiére</Link></li>
              {/* <li><Link to="/Timesheet/personnes">Timesheets par personne</Link></li> */}
            </ul>
          </li>
          <li className={currentActif == '/Rapports' ? "main-menu actif" : 'main-menu'} onClick={e => {
              setCurrentActif((currentActif == '/Rapports') ? '' : '/Rapports');
            }}>
            <Link to="#">Rapports</Link>
            <ul className={currentActif == '/Rapports' ? "submenu actif" : 'submenu'}>
              <li><Link to="/Rapports/projets-heures">Projets & Heures</Link></li>
              <li><Link to="/Rapports/couts-heures/projet">Côut & Heures par projet</Link></li>
              <li><Link to="/Rapports/weekly">Envoyer un rapport</Link></li>
              <li><Link to="/Rapports/global/">Envoyer un global</Link></li>
            </ul>
          </li>
          <li><Link to="/Parametrages">Parametrages</Link></li>
        </ul>
      </div>

      <div onClick={e => {
        ALERT.alert('warning', 'Déconnexion', 'Voulez-vous vraiment vous déconnecter ?', ['NON', 'OUI'], true)
        .then(willLoggout => {
          if (willLoggout) {
            STORAGE.loggout();
            history.push("/Login");
          }
        });
      }} className="btn-logout">
        <a href="#">Déconnexion</a>
      </div>

    </div>
  );
}

export default Aside;
