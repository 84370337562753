import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highcharts3d from 'highcharts/highcharts-3d'
import HighchartsExporting from 'highcharts/modules/exporting'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import QUERY from '../../lib/query';
import Aside from '../Aside/Aside';
import { Editor } from '@tinymce/tinymce-react';

import Lottie from 'react-lottie';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import STORAGE from '../../lib/storage';
const processFile = '../../assets/images/process.json';

require("highcharts/modules/timeline")(Highcharts);

if (typeof Highcharts === 'object') {
    highcharts3d(Highcharts)
    HighchartsExporting(Highcharts)
}

const options = [
    { label: 'Thing 1', value: 1},
    { label: 'Thing 2', value: 2},
];

function TimesheetGlobal() {
    const [mailSent, setMailSent] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDate2, setCurrentDate2] = useState(new Date());
    const [destinataire, setDestinataire] = useState('dev@pulse.digital');
    const [copyEmail, setCopyEmail] = useState('');
    const [subject, setSubject] = useState(`Rapport weekly du ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()}`);
    const [mailContent, setMailContent] = useState(`
        Bonsoir <strong>PULSE.digital</strong>, 
        <br/>
        Bexio manager vous fait parvenir le weekly du <b> ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()} </b><br/>
        Bonne fin de weekend et à la prochaine!
    `);

    const handleEditorChange = (content, editor) => {
        setMailContent(content);
    }

    const sendEmail = () => {
        var date1 = currentDate;
        date1.setHours(0);
        date1.setMinutes(0);
        date1.setSeconds(0);
        var date2 = currentDate2;
        date2.setHours(23);
        date2.setMinutes(59);
        date2.setSeconds(59);
        setCurrentDate(date1);
        setCurrentDate2(date2);
        setMailSent(true);
        QUERY.sendEmail({
            email: destinataire,
            debut: currentDate.toISOString(),
            fin: currentDate2.toISOString(),
            cc_email: copyEmail,
            objet_email: subject,
            content_email: mailContent
        }).then((param) => {
            // setMailSent(true);
        })
        .catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        var date = STORAGE.getDateRange();
        if (!date) {
            date = STORAGE.getDefaultRange();
        }
        const date1 = new Date(date.start);
        const date2 = new Date(date.end);

        setCurrentDate(date1);
        setCurrentDate2(date2);
        QUERY.GetEmailAdministration().then((param) => {
            param && param[0] && setDestinataire(param[0].valeur);
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        setMailContent(`
            Bonsoir <strong>PULSE.digital</strong>, 
            <br/>
            Bexio manager vous fait parvenir le weekly du <b> ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()} </b><br/>
            Bonne fin de weekend et à la prochaine!
        `);
        setSubject(`Rapport weekly du ${('0' + (currentDate.getDate())).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} au ${('0' + (currentDate2.getDate())).slice(-2)}-${('0' + (currentDate2.getMonth() + 1)).slice(-2)}-${currentDate2.getFullYear()}`)
    }, [currentDate, currentDate2]);

    const handleDateChange = (date) => {
        setCurrentDate(date[0]);
        setCurrentDate2(date[1]);
        STORAGE.setDateRange(date[0], date[1]);
    }

  return (
    <>
        <Aside/>
        <div className="content-page">
            <h1>Envoyer un rapport de timesheet</h1>

            {/* <ReactMultiSelectCheckboxes
                className="multi-select"
                options={users} 
                onChange={handleUserChanges}
                value={currentUsers}
            />

            <ReactMultiSelectCheckboxes
                className="multi-select"
                options={projets} 
                onChange={handleProjetChanges}
                value={currentProjets}
            /> */}

            <DateRangePicker className="time-picker"
                onChange={e => handleDateChange(e)}
                value={[currentDate, currentDate2]}
            />

            <div className="full-width">

                <div className="list-winner col-md-12">
                    <div className="content" style={{'padding': '90px'}}>
                        {
                            mailSent &&
                            <div>
                                {/* <Lottie 
                                    options={defaultOptions}
                                    height={400}
                                    width={400}
                                /> */}
                                <h3>Bonjour, Je suis Jarvis,</h3>
                                <h3>Je suis l'IA de Pulse.digital, ravis d'être l'intelligence artificielle qui vous prépare votre rapport. Le mail est en cours de préparation. </h3>
                                <Player
                                    autoplay
                                    loop
                                    src="https://bexio-manager.prodn.pulsweb.ch/uploads/process.json"
                                    style={{ height: '550px', width: '900px' }}
                                    >
                                </Player>
                                <p style={{'float': 'right', 'fontWeight': 'bold'}}>Merci et à la prochaine !</p>
                            </div>
                        }
                        {
                            !mailSent && 
                            <div className="content-email">
                                <h2 className='header-email'>Nouveau rapport</h2>
                                <div className="img-diagramme">
                                    <div className="form-add email-form" style={{'width': '100%', 'height': 'auto', 'right': '0%', 'position': 'relative'}}>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>From : </label>
                                            <input type="text" name='valeur' disabled value="jarvis@pulse.digital"/>
                                        </div>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>To : </label>
                                            <input type="text" name='valeur' value={destinataire} onChange={e => setDestinataire(e.target.value)}/>
                                        </div>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>CC : </label>
                                            <input value={copyEmail} onChange={e => setCopyEmail(e.target.value)} type="text" name='valeur'/>
                                        </div>
                                        <div className="form-text">
                                            <label style={{'padding': '0px 0'}}>Subject : </label>
                                            <input type="text" name='valeur' value={subject} onChange={e => setSubject(e.target.value)}/>
                                        </div>
                                        <Editor
                                            apiKey="40d0oks2mzhjlpyr7emqiwtddoqrqri85vqbrr8fjm8ze48z"
                                            initialValue={mailContent}
                                            value={mailContent}
                                            name='content'
                                            init={{
                                            height: 200,
                                            menubar: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic color backcolor underline | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />

                                        <div className="form-submit" style={{'float': 'right'}}>
                                            <input onClick={e => sendEmail()} style={{'padding': '0px 0px', 'fontSize': '40px'}} type="submit" name="ajouter" id="ajouter" value="💌" className="ajouter sub" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    </>
  );
}

export default TimesheetGlobal;
