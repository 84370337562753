import React, { useEffect, useState } from 'react'
import API from '../../lib/api';
import QUERY from '../../lib/query';
import DELETE from '../../lib/delete';
import Table from '../Table';
import profil from '../../assets/images/avatar.png';
import SOCKET from '../../lib/socket';

function Listes({setOpenCreate, setOpenEdit, openCreate, refresh, setRefresh, setUpdate, setNewInput}) {
  
  const [list, setList] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [size, setSize] = useState(100);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [action, setAction] = useState({});
  const [csvData, setCsvData] = useState([]);

  const headers= [
    "Utilisateur",
    "Projet",
    "Date",
    "Durée",
    "Actions"
  ]

  const initData = () => {
    QUERY.allFilteredTimesheets({page, size, search})
    .then((data) => {
      console.log(data)
      setTotalRow(data && data._allTimesheetsMeta && data._allTimesheetsMeta.count ? data._allTimesheetsMeta.count : 0)
      let list = data && data.allTimesheets ? data.allTimesheets.map(row => {    
        return [
        {
          type: 'ID',
          value: row.id
        },
        {
          type: 'TEXT',
          value: row?.utilisateur?.firstname + ' ' + row?.utilisateur?.lastname
        },
        {
          type: 'TEXT',
          value: row?.projet?.name
        },
        {
          type: 'TEXT',
          value: row.date ? (`${('0' + ((new Date(row.date)).getDate())).slice(-2)}-${('0' + ((new Date(row.date)).getMonth() + 1)).slice(-2)}-${(new Date(row.date)).getFullYear()}`) : '..-..-..'
        },
        {
          type: 'TEXT',
          value: row.duration
        },
        {
          type: 'ACTION',
          value: [
            // {
            //   type: 'edit',
            //   value: row.id
            // },
            {
              type: 'details',
              value: row.id
            },
            // {
            //   type: 'blocked',
            //   value: row.id
            // }
          ]
        }
      ]}) : [];
      setList(list);
    })
    .catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    initData();
    SOCKET.on("evolutionChargement", data => {
      initData();
    });
  }, []);

  useEffect(() => {
    console.log('page : ', page, ' size : ', size)
    initData();
  }, [page, size, search]);

  useEffect(() => {
    if(refresh) {
      initData();
      setRefresh(false);
    }
  }, [refresh])

  useEffect(() => {
    switch (action.type) {
      case 'details':
        console.log("details", action);
        QUERY.getTimesheetById({id: action.value})
        .then((data) => {
          setUpdate(data);
          setOpenCreate(true);
        })
        .catch((err) => {
          console.log(err);
        })
        setAction({});
        break;

      default:
        break;
    }
  }, [action]);

  return (
    <Table typeSynchro={'TIMESHEET'} pagination={true} page={page} size={size} setPage={setPage} search={search} setSearch={setSearch} setSize={setSize} totalPage={parseInt(totalRow%size == 0 ? (totalRow / size) : ((totalRow / size) + 1))} noAdd={true} csv={false} sync={true} csvData={csvData} title="Listes des timesheets" headers={headers} list={list} setOpenCreate={setOpenCreate} setOpenEdit={setOpenEdit} setNewInput={setNewInput} setAction={setAction}/>
  );
}

export default Listes;