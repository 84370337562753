import React, { useState } from 'react'
import QUERY from '../../lib/query';
import Details from '../Details'

function Form({update, openCreate, setOpenCreate}) {
  
  const [inputs, setInputs] = useState([
    {
      key: 'Id Bexio',
      value: update.id_bexio
    },
    {
      key: 'Nom',
      value: update.name
    },
    {
      key: 'Nombre total de projets',
      value: update.projets ? (update.projets.length + ' projet(s)') : '0 projets'
    }
  ]);
  
  const [lists, setLists] = useState([]);
  const [allLists, setAllLists] = useState([
    {
      name: 'projets',
      title: 'Projets rattachés',
      items: update.projets.map((projet) => (
        {
          value: projet.name,
          floattedValue: projet.name
        }
      ))
    }
  ]);
  const [bons, setBons] = useState(
    update.bonGagnes
  );

  return (
    <Details titre={`Détail type de projet : ${update.name}`} lists={lists} inputs={inputs} setOpenCreate={setOpenCreate} openCreate={openCreate} allLists={allLists}/>
  );
}

export default Form;
