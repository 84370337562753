import ALERT from './alert';
import MUTATION from './mutation';

const DELETE = async (titre, list, id, accordee=false) => {
    const yesForDelete = await ALERT.alert('warning', 'Êtes-vous sûr de vouloir supprimer ' + titre + ' ?', 'La suppression est irréversible', ['NON', 'OUI'], true)
    if (yesForDelete) {
        ALERT.alert('success', 'Suppression', titre + ' a bien été ' + (accordee ? 'supprimée !' : 'supprimé !'));
        return MUTATION.DELETE({list, id});
    }
    else {
        return;
    }
}

export default {
    DELETE
};