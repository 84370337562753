const data = [
    {
        name: "Gris clair",
        hex: "#D0D0D0",
    }, 
    {
        name: "Gris foncé",
        hex: "#5C5C5C",
    }, 
    {
        name: "Vert clair",
        hex: "#A8CA59",
    }, 
    {
        name: "Vert foncé",
        hex: "#43893D",
    }, 
    {
        name: "Vert",
        hex: "#3DB16A",
    }, 
    {
        name: "Bleu clair",
        hex: "#93D1E3",
    }, 
    {
        name: "Rose",
        hex: "#E6B1CB",
    }, 
    {
        name: "Bleu foncé",
        hex: "#007493",
    }, 
    {
        name: "Jaune vif",
        hex: "#F7E00F",
    }, 
    {
        name: "Turquoise",
        hex: "#85C7AD",
    }, 
    {
        name: "Jaune",
        hex: "#F1CC68",
    }, 
    {
        name: "Rouge",
        hex: "#DD5359",
    }, 
    {
        name: "Orange",
        hex: "#E58C6D",
    }, 
    {
        name: "Orange vif",
        hex: "#E89619",
    }, 
    {
        name: "Bleu foncé",
        hex: "#5762A7",
    }
];
export default data;