import React, { useState, useEffect } from 'react'
import profil from '../../assets/images/avatar.png';
import QUERY from '../../lib/query';
import API from '../../lib/api';
import MUTATION from '../../lib/mutation';
import CREATE from '../../lib/createItem'
import Form from '../Form';
import ALERT from '../../lib/alert';

function Edit({openCreate, setOpenCreate, setRefresh, update, setUpdate, newInput}) {

  const [inputs, setInputs] = useState([]);
  const [titre, setTitre] = useState('Mise à jour du budget de ' + update.name);
  const [list, setList] = useState([]);

  console.log(newInput);

  const handleResult = (data) => {
    console.log(data);
    CREATE.CREATE('le projet', 'Projet', `{
      pr_budget_type_amount: ${data.pr_budget_type_amount}
      }`,
      data.id ? data.id : null
    )
    .then((item) => {
      setRefresh(true);
      setOpenCreate(false);
    })
    .catch((err) => {
      console.log(err)
      setRefresh(true)
      setOpenCreate(false);
    })
  }

  //Password validation
const passwordValid = (password) => { 
    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const hasNumber = /\d/.test(password);
    const hasCapitalizedCharacter = /[A-Z]/.test(password);
    const hasSpecialChar = specialChars.test(password);
    
    if (hasNumber && hasCapitalizedCharacter && hasSpecialChar && password.length >= 8) {
    return true;
    } else {
    return false;
    }
}

  useEffect(() => {
    console.log(update);
    if (update && update.id) {
      setInputs([
        {
          type: 'id',
          name: 'id',
          defaultValue: update.id
        },
        {
          type: 'text',
          libelle: "Nom",
          name: 'nom',
          defaultValue: update.name,
          required: false,
          disabled: true
        },
        {
          type: 'text',
          libelle: "Type",
          name: 'type',
          defaultValue: update?.typeProject?.name,
          required: false,
          disabled: true
        },
        {
          type: 'text',
          libelle: "Contact",
          name: 'contact',
          defaultValue: update?.contact?.name_1,
          required: false,
          disabled: true
        },
        {
          type: 'text',
          libelle: "Date de debut",
          name: 'start_date',
          defaultValue: update.start_date ? (`${('0' + ((new Date(update.start_date)).getDate())).slice(-2)}-${('0' + ((new Date(update.start_date)).getMonth() + 1)).slice(-2)}-${(new Date(update.start_date)).getFullYear()}`) : '..-..-..',
          required: false,
          disabled: true
        },
        {
          type: 'number',
          libelle: "Budget",
          name: 'pr_budget_type_amount',
          defaultValue: update?.pr_budget_type_amount,
          required: true
        }
      ])
      setUpdate({});
    }
  }, [update])

  return (
    <Form inputs={inputs} titre={titre} list={list} handleResult={handleResult} openCreate={openCreate} setOpenCreate={setOpenCreate}/>
  );
}

export default Edit;
